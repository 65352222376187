import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ArrowSvg,
  ArtistsSvg,
  DashboardSvg,
  EventsSvg,
  GalleriesSvg,
  LogoSvg,
  MuseumsSvg,
  UsersSvg,
} from '../../assets/SVGs';
import useUserStore from '../../store';
import { hasPermission } from '../../utils';

interface SidebarProps {
  isCollapsed: boolean;
  toggleSidebar: () => void;
}
export interface MenuItem {
  name: string;
  icon: any;
  path: string;
  permission?: string;
}

export const menuItems: MenuItem[] = [
  {
    name: 'dashboard',
    icon: DashboardSvg,
    path: '/dashboard',
    permission: 'view:dashboardStatistics',
  },
  {
    name: 'artworks',
    icon: DashboardSvg,
    path: '/dashboard/artwork',
    permission: 'view:dashboardArtwork',
  },
  {
    name: 'users',
    icon: UsersSvg,
    path: '/dashboard/users',
    permission: 'view:dashboardUsers',
  },
  {
    name: 'artists',
    icon: ArtistsSvg,
    path: '/dashboard/artists',
    permission: 'view:dashboardArtist',
  },
  {
    name: 'galleries',
    icon: GalleriesSvg,
    path: '/dashboard/galleries',
    permission: 'view:dashboardGallery',
  },
  {
    name: 'museums',
    icon: MuseumsSvg,
    path: '/dashboard/museums',
    permission: 'view:dashboardMuseums',
  },
  {
    name: 'events',
    icon: EventsSvg,
    path: '/dashboard/events',
    permission: 'view:dashboardEvents',
  },
  { name: 'home', icon: DashboardSvg, path: '/' },
];

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, toggleSidebar }) => {
  const location = useLocation();
  // hasPermission(userType, 'view:dashboardusers')

  const { t } = useTranslation();
  const { userType } = useUserStore();

  return (
    <div
      className={`bg-primary h-screen p-5 pt-6 fixed top-0 left-0 z-100 ${
        isCollapsed ? 'w-20' : 'w-64'
      } duration-300`}
    >
      <button
        className="absolute top-5 right-5 p-2 rounded-full"
        onClick={toggleSidebar}
      >
        <ArrowSvg isCollapsed={isCollapsed} />
      </button>

      <div className="flex items-center gap-4 mb-3 md:mb-4 xl:mb-8 ">
        <span className="text-white text-2xl font-bold">
          {isCollapsed ? (
            <div className="mt-12"></div>
          ) : (
            <NavLink to="/">
              <LogoSvg />
            </NavLink>
          )}
        </span>
      </div>

      <ul className="max-h-[67vh] overflow-auto">
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.path;
          const isShow = hasPermission(userType, item.permission);

          if (!isShow) {
            return;
          }

          return (
            <li
              key={index}
              className="text-white text-lg mb-2 xl:mb-6 font-semibold"
            >
              <Link
                to={item.path}
                className={`flex items-center gap-4 p-2 hover:bg-[#F2EFE8]  rounded-lg ${
                  isActive ? 'bg-[#F2EFE8] text-[#202020]' : ''
                }`}
              >
                <span className="text-xl ">
                  <item.icon active={isActive} />
                </span>
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>
                  {t(item.name)}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
