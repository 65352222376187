import { Navigate } from 'react-router-dom';

function GuestRoute({ children, isLogin }) {
  if (isLogin) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default GuestRoute;
