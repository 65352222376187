import { create } from 'zustand';
import zukeeper from 'zukeeper';
import { apiInstance } from 'config/axios';

const useArtistStore = create(
  zukeeper((set) => {
    const initialState = {
      artists: [],
      loading: false,
      error: null,
      recentArtists: [],
      recentLoading: false,
      recentError: null,
      totalArtists: 0, // To handle pagination
      totalResults: 0,
      totalPages: 0,
      authorDetails: {},
    };

    set(initialState);

    return {
      ...initialState,
      fetchRecentArtists: async () => {
        set({ recentLoading: true, recentError: null });
        try {
          const response = await apiInstance.get('/api/artists', {
            params: { limit: 4 },
          });
          if (response.data.data && response.data.data.artists.length) {
            set({
              recentArtists: response.data.data.artists,
              recentLoading: false,
            });
          } else {
            set({ recentLoading: false });
          }
        } catch (error) {
          set({
            recentLoading: false,
            recentError:
              error.response?.data?.message ||
              'Failed to fetch recent artists. Please try again.',
          });
        }
      },

      fetchArtists: async ({
        page = 1,
        limit = 10,
        search = '',
        city = '',
        country = '',
      }) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get('/api/artists', {
            params: { page, limit, search, city, country },
          });
          set({
            loading: false,
            artists: response.data.data.artists,
            totalArtists: response.data.totalPages, // Update the total artists count for pagination
            totalPages: response.data?.totalPages || 0,
            totalResults: response.data?.totalResults || 0,
          });
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch artists. Please try again.',
          });
        }
      },

      fetchArtistById: async (id) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get(`/api/artists/${id}`);
          set({ authorDetails: response.data?.data?.artist });
          return response.data; // Return artist data
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch artist details. Please try again.',
          });
        }
      },

      // Fetch artworks for an artist
      fetchArtistArtworks: async (id) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get(`/api/artists/${id}/artworks`);
          return response.data; // Return artworks data for the artist
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch artworks. Please try again.',
          });
        }
      },

      createArtist: async (artistData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post('/api/artists', artistData);
          set((state) => ({
            artists: [...state.artists, response.data],
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to create artist. Please try again.',
          });
        }
      },

      updateArtist: async (id, artistData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.put(
            `/api/artists/${id}`,
            artistData,
          );
          set((state) => ({
            artists: state.artists.map((artist) =>
              artist.id === id ? { ...artist, ...response.data } : artist,
            ),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to update artist. Please try again.',
          });
        }
      },

      deleteArtist: async (id) => {
        set({ loading: true, error: null });
        try {
          await apiInstance.delete(`/api/artists/${id}`);
          set((state) => ({
            artists: state.artists.filter((artist) => artist.id !== id),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to delete artist. Please try again.',
          });
        }
      },

      // Verify artist's phone (for specific flow)
      verifyArtistPhone: async (code) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post('/api/artists/verify-phone', {
            code,
          });
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to verify artist. Please try again.',
          });
        }
      },

      // Upload profile picture for an artist
      uploadProfilePicture: async (id, formData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            `/api/artists/${id}/profile-picture`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to upload profile picture. Please try again.',
          });
        }
      },

      // Admin fetch all artists
      fetchAllArtistsAdmin: async () => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get('/api/artists/admin/all');
          set({
            artists: response.data.data.artists,
            loading: false,
          });
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch all artists. Please try again.',
          });
        }
      },

      // Reset error state
      clearError: () => set({ error: null }),

      // Reset all artist data
      resetArtists: () => set({ artists: [], loading: false, error: null }),
    };
  }),
);

window.store = useArtistStore; // For debugging

export default useArtistStore;
