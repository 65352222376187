import { IModalState, MODEL_INITIAL_STATE } from 'common';
import { useCallback, useState } from 'react';

export const useModal = () => {
  const [modalState, setModalState] =
    useState<IModalState>(MODEL_INITIAL_STATE);
  const modalStateHandler = useCallback(
    (key: keyof IModalState, val: boolean) =>
      setModalState((prev) => ({
        ...prev,
        [key]: val,
      })),
    [],
  );
  return {
    modalState,
    setModalState,
    modalStateHandler,
  };
};
