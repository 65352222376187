import { apiInstance } from 'config/axios';
import { create } from 'zustand';

const useMuseumStore = create((set) => {
  const initialState = {
    total: 0,
    museums: [],
    artworks: [],
    recentMuseums: [],
    loading: false,
    museumError: null,
    recentError: null,
    museumDetails: null,
    artworksError: null,
    recentLoading: false,
    artworksLoading: false,
    totalResults: 0,
    totalPages: 0,
  };

  set(initialState);

  const handleError = (error, action) => {

    let errorMessage = 'An error occurred. Please try again.';
    if (error.response) {
      const { status, data } = error.response;
      if (status === 401) {
        errorMessage = 'Unauthorized access. Please login again.';
      } else if (status === 500) {
        errorMessage = 'Internal server error. Please try again later.';
      } else if (data?.message) {
        errorMessage = data.message;
      }
    }

    switch (action) {
      case 'museums':
        set({ museumError: errorMessage, loading: false });
        break;
      case 'recent':
        set({ recentError: errorMessage, recentLoading: false });
        break;
      case 'artworks':
        set({ artworksError: errorMessage, artworksLoading: false });
        break;
      case 'create':
        set({ museumError: errorMessage, loading: false });
        break;
      case 'update':
        set({ museumError: errorMessage, loading: false });
        break;
      case 'delete':
        set({ museumError: errorMessage, loading: false });
        break;
      default:
        set({
          museumError: 'An unexpected error occurred',
          loading: false,
        });
    }
  };

  return {
    ...initialState,

    fetchRecentMuseums: async (limit = 4) => {
      set({ recentLoading: true, recentError: null });
      try {
        const response = await apiInstance.get('/api/museums', {
          params: { limit },
        });

        if (response.status === 200) {
          set({
            recentMuseums: response.data?.data?.museums || [],
            recentLoading: false,
          });
        } else {
          set({
            recentError: 'Failed to fetch recent museums. Please try again.',
            recentLoading: false,
          });
        }
      } catch (error) {
        handleError(error, 'recent');
      }
    },

    fetchMuseums: async ({ page, limit = 10, search }) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.get('/api/museums', {
          params: {
            page,
            limit,
            search,
          },
        });

        if (response.status === 200) {
          set({
            museums: response.data?.data?.museums || [],
            loading: false,
            totalResults: response.data?.data?.totalResults || 0,
            totalPages: response.data?.data?.totalPages || 0,
            total: response.data.totalPages,
          });
        } else {
          set({
            museumError: 'Failed to fetch museums. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'museums');
      }
    },

    fetchMuseumById: async (id) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.get(`/api/museums/${id}`);

        if (response.status === 200) {
          set({
            museumDetails: response.data?.data?.museum || null,
            loading: false,
          });
        } else {
          set({
            museumError: 'Failed to fetch museum details. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'museum');
      }
    },

    // Create a new museum (Admin only)
    createMuseum: async (museumData) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.post(
          '/api/museums/admin',
          museumData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.status === 201) {
          set((state) => ({
            museums: [...state.museums, response.data],
            loading: false,
          }));
        } else {
          set({
            museumError: 'Failed to create museum. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'create');
      }
    },

    updateMuseum: async (id, museumData) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.put(
          `/api/museums/admin/${id}`,
          museumData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.status === 200) {
          set((state) => ({
            museums: state.museums.map((museum) =>
              museum.id === id ? { ...museum, ...response.data } : museum,
            ),
            loading: false,
          }));
        } else {
          set({
            museumError: 'Failed to update museum. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'update');
      }
    },

    deleteMuseum: async (id) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.delete(`/api/museums/admin/${id}`);

        if (response.status === 200) {
          set((state) => ({
            museums: state.museums.filter((museum) => museum.id !== id),
            loading: false,
          }));
        } else {
          set({
            museumError: 'Failed to delete museum. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'delete');
      }
    },

    // Admin upload artwork for a museum
    uploadMuseumArtwork: async (id, artworkData) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.post(
          `/api/museums/admin/${id}/upload-artwork`,
          artworkData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        if (response.status === 201) {
          set({
            loading: false,
          });
        } else {
          set({
            museumError: 'Failed to upload artwork. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'uploadArtwork');
      }
    },
    // Fetch artworks for a museum
    fetchMuseumArtworks: async (id) => {
      set({ artworksLoading: true, artworksError: null });
      try {
        const response = await apiInstance.get(`/api/museums/${id}/artworks`);

        if (response.status === 200) {
          set({
            artworks: response.data?.data?.artworks || [],
            artworksLoading: false,
          });
        } else {
          set({
            artworksError: 'Failed to fetch artworks. Please try again.',
            artworksLoading: false,
          });
        }
      } catch (error) {
        handleError(error, 'artworks');
      }
    },
    // Admin bulk import artworks for a museum
    bulkImportArtworks: async (id, artworksData) => {
      set({ loading: true, museumError: null });
      try {
        const response = await apiInstance.post(
          `/api/museums/admin/${id}/bulk-import`,
          artworksData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        if (response.status === 201) {
          set({
            loading: false,
          });
        } else {
          set({
            museumError: 'Failed to bulk import artworks. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'bulkImport');
      }
    },
  };
});

export default useMuseumStore;
