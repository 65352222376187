import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

const CustomTextInput = ({
  control,
  name,
  label,
  type,
  placeholder,
  extraStyle,
  rows,
}) => {
  const isMultiline = useMemo(() => type === 'multiline', [type]);

  return (
    <div className={`w-full mb-4 ${extraStyle || ''}`}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              {label}
            </label>
            {isMultiline ? (
              <textarea
                {...field}
                id={name}
                rows={rows || 3}
                placeholder={placeholder || ''}
                className={`mt-1 block w-full outline-none rounded-md border p-1 resize-none border-gray-300 shadow-sm focus:border-primary focus:ring-primary ${
                  error ? 'border-red-500' : ''
                }`}
              />
            ) : (
              <input
                {...field}
                id={name}
                type={type || 'text'}
                placeholder={placeholder || ''}
                className={`mt-1 block w-full rounded-md outline-none border p-1 border-gray-300 shadow-sm focus:border-primary focus:ring-primary ${
                  error ? 'border-red-500' : ''
                }`}
              />
            )}
            {error && (
              <p className="mt-1 text-sm text-red-500">{error.message}</p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default CustomTextInput;
