import { useEffect, useState } from 'react';
import Viewer from 'react-viewer';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EventCard from '../UiComponents/CardItem';

import useEventStore from '../../store/events';
import Loader from '../UiComponents/Loader';
import MapWithMarker from '../UiComponents/MapWithMarker';

const EventDetail = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    eventDetails,
    fetchEventById,
    recentEvents,
    fetchRecentEvents,
    eventLoading,
    recentLoading,
  } = useEventStore();

  useEffect(() => {
    if (id) {
      fetchEventById(id);
      fetchRecentEvents();
    }
  }, [id, fetchEventById]);

  const openViewer = () => {
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  if (eventLoading || recentLoading) {
    return (
      <div className="text-center mt-20">
        <Loader /> <p>{t('loading')}...</p>
      </div>
    );
  }

  if (!eventDetails) {
    return <div className="text-center mt-20">Data not found.</div>;
  }

  const imageUrl = eventDetails?.imageUrl;
  return (
    <div className="min-h-screen px-4 md:px-20">
      <div className="flex flex-col md:flex-row gap-4 md:gap-10 my-8">
        <div
          className="w-full bg-gray-300 min-h-[300px] md:min-h-[700px] relative p-5"
          style={{
            backgroundImage: `url(${eventDetails?.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          onClick={openViewer}
        >
          <div className="date text-2xl md:text-4xl italic mb-8 text-end">
            {eventDetails?.startDate}
          </div>
          <div className="text-2xl md:text-4xl bottom-6 absolute">
            {t('eventType')} : {eventDetails?.EventType?.title}
          </div>
        </div>
        <Viewer
          visible={visible}
          onClose={closeViewer}
          images={[{ src: imageUrl, alt: 'Event Image' }]}
        />
      </div>

      <div className="flex flex-col md:flex-row md:items-center justify-between py-8">
        <div className="flex items-center mb-4 md:mb-0">
          <span className="text-lg md:text-xl font-semibold mr-2 md:mr-4">
            {t('tags')}:
          </span>
          <div className="flex flex-wrap space-x-2 md:space-x-4">
            {eventDetails?.tags.map((tag) => (
              <div
                key={tag}
                className="bg-primary text-white text-base md:text-xl px-2 py-2 md:px-6 md:py-3 rounded"
              >
                {t(tag)}
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center">
          <div className="bg-primary w-12 h-12 md:w-16 md:h-16 rounded-lg mr-2 md:mr-4"></div>
          <div>
            <span className="text-md md:text-lg font-semibold">
              {t('organizer')}:
            </span>
            <p>Art Museum Uzbekistan</p>
          </div>
        </div>
      </div>

      <EventDetailComponent data={eventDetails} />

      <div className="py-8">
        <div className="flex justify-between my-3">
          <h2 className="font-cormorant font-bold text-2xl md:text-3xl">
            {t('otherEvents')}
          </h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {recentEvents?.map((item) => (
            <EventCard
              isEventType={true}
              key={item.id}
              img={item.imageUrl}
              type={item.type}
              title={item.title}
              style={item.style}
              price={item.price}
              author={item.author}
              description={item.description}
              link={'/event-detail/' + item.id}
            />
          ))}
        </div>
      </div>
      <div className="my-8" />
    </div>
  );
};

export default EventDetail;

const EventDetailComponent = ({ data }) => {
  const { t } = useTranslation();
  const { description, location } = data;

  if (!data) {
    return null;
  }
  return (
    <div className="flex flex-col md:flex-row items-center justify-center w-full py-8">
      <div className="w-full mb-4 md:mb-0 md:w-8/12 md:pr-20">
        <h1 className="text-xl md:text-2xl font-bold mb-4">
          {t('description')}
        </h1>
        <p className="text-sm md:text-base">
          {description ??
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        </p>
        <div className="flex flex-col space-y-4 py-8">
          <div className="flex flex-wrap justify-between space-y-2 md:space-y-0">
            <div className="flex items-center space-x-2">
              <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-md">
                9 сентября, 2024
              </div>
              <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-md">
                17:00
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-md">
                23 сентября, 2024
              </div>
              <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-md">
                13:00
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mt-5 space-x-2">
            <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-md">
              12 сентября, 2024
            </div>
            <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-md">
              12:00
            </div>
          </div>
        </div>

        <div className="">
          <h1 className="text-xl md:text-2xl font-bold mb-4 mt-10">Цена</h1>
          <div className="flex items-center mb-2 space-x-2">
            <div className="text-sm md:text-xl min-w-[100px] md:min-w-[200px]">
              9 сентября, 2024
            </div>
            <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-8 rounded-md">
              610 000
            </div>
          </div>

          <div className="flex items-center mt-2 space-x-2">
            <div className="text-sm md:text-xl min-w-[100px] md:min-w-[200px]">
              23 сентября, 2024
            </div>
            <div className="bg-primary text-white py-1 px-4 md:px-4 rounded-lg font-serif">
              510 000
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4">{t('location')}</h2>
        <MapWithMarker />
        <div className="w-full mt-6">
          <h3 className="text-lg md:text-xl font-semibold">{t('address')}</h3>
          <p className="text-sm md:text-base">{location}</p>

          <h3 className="text-lg md:text-xl font-semibold mt-4">
            {t('phone')}
          </h3>
          <p className="text-sm md:text-base">+998 XX XXX XX XX</p>
        </div>
      </div>
    </div>
  );
};
