const Loader = ({
  size = 'w-16 h-16',
  color = 'border-t-4 border-primary',
}) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={`border-4 ${color} rounded-full ${size} animate-spin`}
        style={{ borderTopColor: 'transparent' }}
      ></div>
    </div>
  );
};

export default Loader;
