import { create } from 'zustand';
import zukeeper from 'zukeeper';
import { callApi } from '../config/services';
import { apiInstance } from 'config/axios';

const defaultUser = {
  id: '',
  email: '',
  listings: [],
  username: '',
  phoneNumber: '',
  profilePicture: '',
  userType: 'user', // Options: 'buyer', 'seller', 'admin'
};

const useUserStore = create(
  zukeeper((set) => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    const storedType = localStorage.getItem('userType');

    const initialState = {
      error: null,
      allUsers: [],
      loading: false,
      allLoading: false,
      token: storedToken || null,
      userType: storedType || 'user',
      isAuthenticated: !!storedUser && !!storedToken,
      user: storedUser ? JSON.parse(storedUser) : { ...defaultUser },
      totalResults: 0,
      totalPages: 0,
    };

    set(initialState);

    return {
      ...initialState,

      setError: (error) => set({ error }),
      clearError: () => set({ error: null }),

      login: async (credentials, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            '/api/users/login',
            credentials,
          );
          const { token, user, userType } = response.data;

          localStorage.setItem('token', token);
          localStorage.setItem('userType', userType);
          localStorage.setItem('user', JSON.stringify(user));

          set({
            userType,
            user,
            token,
            loading: false,
            isAuthenticated: true,
          });

          callback?.(null, user);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Login failed. Please try again.',
          });
          callback?.(error, null);
        }
      },
      googleLogin: async (code, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post('api/oauth/google', { code });

          const { token, user, userType } = response.data;

          localStorage.setItem('token', token);
          localStorage.setItem('userType', userType);
          localStorage.setItem('user', JSON.stringify(user));

          set({
            userType,
            user,
            token,
            loading: false,
            isAuthenticated: true,
          });

          callback?.(null, user);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Google login failed. Please try again.',
          });

          callback?.(error, null);
        }
      },

      register: async (userData, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            '/api/users/register',
            userData,
          );
          if (response.status === 201) {
            const user = response.data;

            localStorage.setItem('user', JSON.stringify(user));

            set({
              user,
              error: null,
              loading: false,
            });

            callback?.(null, user);
          } else {
            throw new Error('Registration failed.');
          }
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Registration failed. Please try again.',
          });
          callback?.(error, null);
        }
      },

      logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('userType');

        set({
          user: { ...defaultUser },
          isAuthenticated: false,
          userType: 'user',
          token: null,
          error: null,
        });
      },

      updateUser: async (updatedData, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.put(
            `/api/users/${updatedData.id}`,
            updatedData,
          );
          set({
            user: { ...response.data, updatedAt: new Date() },
            loading: false,
          });
          callback?.(null, response.data);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to update user. Please try again.',
          });
          callback?.(error, null);
        }
      },

      resetPassword: async (email, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            '/api/users/forgot-password',
            {
              email,
            },
          );
          set({ loading: false });
          callback?.(null, response.data.message);
        } catch (error) {
          const msg =
            error.response?.data?.message ||
            'Failed to send reset instructions. Please try again.';
          set({
            loading: false,
            error: msg,
          });
          callback?.(msg, null);
        }
      },

      changePassword: async (currentPassword, newPassword, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            '/api/users/change-password',
            {
              currentPassword,
              newPassword,
            },
          );
          set({ loading: false });
          callback?.(null, response.data.message);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to change password. Please try again.',
          });
          callback?.(error, null);
        }
      },

      // Admin functions for managing users
      blockUser: async (id, reason, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            `/api/users/admin/${id}/block`,
            {
              reason,
            },
          );
          set({ loading: false });
          callback?.(null, response.data);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to block user. Please try again.',
          });
          callback?.(error, null);
        }
      },

      unblockUser: async (id, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            `/api/users/admin/${id}/unblock`,
          );
          set({ loading: false });
          callback?.(null, response.data);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to unblock user. Please try again.',
          });
          callback?.(error, null);
        }
      },

      changeUserType: async (id, newUserType, callback) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.patch(
            `/api/users/admin/${id}/change-type`,
            { newUserType },
          );
          set({ loading: false });
          callback?.(null, response.data);
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to change user type. Please try again.',
          });
          callback?.(error, null);
        }
      },

      deleteUser: (id) => {
        console.log('deleteUser', id);
      },

      fetchUsers: async ({ page = 1, limit = 10, search = '' } = {}) => {
        set({ allLoading: true, error: null });
        try {
          const response = await callApi('/api/users', null, {
            page,
            limit,
            search,
          });

          set({
            allUsers: response.data.data ? response.data.data.users : [],
            allLoading: false,
            totalPages: response.data?.totalPages ?? 0,
            totalResults: response.data?.results ?? 0,
          });
        } catch (error) {
          set({
            loading: false,
            allLoading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch artworks. Please try again.',
          });
        }
      },

      resendVerifyEmail: async (email, callback) => {
        try {
          const response = await apiInstance.post(
            '/api/users/resend-verification-email',
            { email },
          );

          callback?.(null, response.data.message);
        } catch (error) {
          const msg =
            error.response?.data?.message ||
            'Failed to send reset instructions. Please try again.';

          callback?.(msg, null);
        }
      },
      setUser: (user) => set({ user: { ...user } }),

      setUserType: (userType) => set({ userType: userType }),
      // Reset user data
      resetUser: () =>
        set({ user: { ...defaultUser }, isAuthenticated: false, token: null }),
    };
  }),
);

window.store = useUserStore; //debuging

export default useUserStore;
