import { AxiosError } from 'axios';
import { TRANSLATION_KEYS } from 'common';
import { TFunction } from 'i18next';
import toast from 'react-hot-toast';

type LabelValueObject = {
  label: string;
  value: string;
};

type Payload = Record<string, any>; // Define a generic type for the payload

export function parseFromPayload<T extends Payload>(payload: T): T {
  const parseValue = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map((item) => parseValue(item));
    }

    if (isLabelValueObject(value)) {
      return value.value;
    }

    if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof File)
    ) {
      const parsedObject: Record<string, any> = {};
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          parsedObject[key] = parseValue(value[key]);
        }
      }
      return parsedObject;
    }

    return value;
  };

  const parsedPayload: any = {};
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      parsedPayload[key] = parseValue(payload[key]);
    }
  }

  return parsedPayload as T;
}

// Helper function to check if an object is of type LabelValueObject
function isLabelValueObject(obj: any): obj is LabelValueObject {
  return obj && typeof obj === 'object' && 'label' in obj && 'value' in obj;
}

export function getSelectOptionHelper(t: TFunction) {
  const keys = TRANSLATION_KEYS(t);
  return (v: string) => {
    const label = keys[v as keyof typeof keys];
    return label
      ? {
          label,
          value: v,
        }
      : '';
  };
}

export function strToOptionsHelper(arr: string[]) {
  return arr?.map((v) => ({ label: v, value: v })) ?? [];
}

export function showMutationError(error: any) {
  let message = error;
  if (error instanceof Error) {
    message = error.message;
  }

  if (error instanceof AxiosError) {
    message = error.response?.data?.message;
  }

  toast.error(message?.slice(0, 100));
}

export const showSuccessMessage = (t: TFunction) => {
  toast.success(t('actionMessages.success'));
};

export const jsonToFormData = (json: any) => {
  const formData = new FormData();
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      if (key == 'tags') {
        formData.append(key, json[key].join(','));
      } else {
        formData.append(key, json[key]);
      }
    }
  }
  return formData;
};
