import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Card = ({
  img,
  title,
  price,
  style,
  author,
  artType,
  gallery,
  link = '',
  description,
  isArtType = false,
  showButton = true,
  authorType = false,
  isEventType = false,
}) => {
  const { t } = useTranslation();

  const renderImage = (img) => (
    <div
      className={`w-full min-h-[220px] h-[200px] bg-gray-300 bg-center bg-cover transition-transform duration-300 ease-in-out transform hover:scale-110`}
      style={{
        backgroundImage: img ? `url(${img})` : undefined,
      }}
    >
      {!img && (
        <div className="flex items-center justify-center h-full text-gray-500">
          No Image Available
        </div>
      )}
    </div>
  );

  return (
    <div className="border border-[#ede9e0] p-2 bg-primaryLight overflow-hidden m-1">
      <div className="w-full min-h-48 mb-2 placeholder-image overflow-hidden">
        {link ? <Link to={link}>{renderImage(img)}</Link> : renderImage(img)}
      </div>
      {isEventType && (
        <>
          <div className="flex justify-between mt-3">
            <h2 className="text-sm text-left font-bold text-[#A89F91]">
              {t(title) || '--'}
            </h2>
            <div className="text-left font-bold text-[#A89F91]">{price}</div>
          </div>
          <p className="text-secondary font-bold text-[20px]">
            {t(description) || '--'}
          </p>
        </>
      )}
      {isArtType && (
        <>
          <h2 className="text-sm sm:text-xl font-bold text-secondary">
            {t(title) || '--'}
          </h2>
          {showButton && (
            <>
              <div className="flex justify-between w-full my-2">
                <button className="bg-primary text-Tlight px-1 md:px-4 xl:px-8 py-1">
                  {t(artType) || '--'}
                </button>
                <button className="bg-primary text-Tlight px-1 md:px-4 xl:px-8 py-1">
                  {t(style) || '--'}
                </button>
              </div>
              <div className="text-primary">{t('artists')}:</div>
              <p className="text-secondary font-bold leading-5 text-[20px]">
                {t(author) || '--'}
              </p>
              <p className="text-primary text-[20px] mt-3">
                {t(gallery) || '--'}
              </p>
            </>
          )}
        </>
      )}

      {authorType && (
        <>
          <h2 className="text-sm sm:text-xl font-bold text-secondary">
            {t(author) || '--'}
          </h2>

          <p className="text-primary font-bold text-sm">{t(artType) || '--'}</p>
        </>
      )}
    </div>
  );
};

export default Card;
