import BaseEventForm from './BaseForm';

const defaultValues = {
  title: '',
  price: '',
  status: '',
  image: null,
  location: '',
  eventDate: '',
  eventTime: '',
  eventType: '',
  description: '',
  tags: [],
};

const CreateEvent = ({ hanldeSubmit }) => {
  return (
    <BaseEventForm submitHandler={hanldeSubmit} defaultValues={defaultValues} />
  );
};

export default CreateEvent;
