import { useState } from 'react';
import toast from 'react-hot-toast';
import queryClient from 'config/query';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import CustomTable from './CustomTable';
import useMuseumStore from '../../store/museum';
import ConfirmationModal from './ConfirmationModal';
import { adminService, userService } from 'services';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import { showMutationError, showSuccessMessage } from 'utils/common';

type MuseumDashboardProps = {
  data: any;
  search: string;
  isLoading: boolean;
  totalPages: number;
  paginationModel: { page: number };
  setSearch: (value: string) => void;
  handlePageChange: (page: number) => void;
};

const StatusBadge = ({ isVerified }: { isVerified: boolean }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`text-center px-2 py-1  whitespace-nowrap rounded-full ${
        isVerified ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
      }`}
    >
      {t(isVerified ? 'verified' : 'notVerified')}
    </div>
  );
};

const MuseumsDashboard = ({
  data,
  totalPages,
  paginationModel,
  handlePageChange,
  search: searchQuery,
  isLoading: isMuseumLoading,
  setSearch: setSearchQuery,
}: MuseumDashboardProps) => {
  const { t } = useTranslation();
  const { museumError: error } = useMuseumStore();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMuseum, setSelectedMuseum] = useState<Record<
    string,
    any
  > | null>(null);

  const { isPending: isDeleting, mutateAsync: deleteMuseum } = useMutation({
    mutationFn: (id: string) => adminService.deleteMuseumById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.MUSEUMS],
      });
      toast.success('Museum deleted successfully');
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const { isPending: loadingVerify, mutateAsync: verifyMuseums } = useMutation({
    mutationFn: (ids: any) => adminService.verifyMuseums(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.MUSEUMS],
      });
    },
  });

  const batchVerify = async (ids: any, cb?: any) => {
    try {
      if (!ids.length) return;
      await verifyMuseums(ids);
      showSuccessMessage(t);

      cb && cb?.();
    } catch (error) {
      showMutationError(error);
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedMuseum) {
      deleteMuseum(selectedMuseum.id);
      setModalOpen(false);
    }
  };

  const handleView = (museum: any) => {
    if (!museum.id) return;
    batchVerify([museum.id]);
  };

  const handleDelete = (museum: any) => {
    setModalOpen(true);
    setSelectedMuseum(museum);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const columns = [
    { Header: 'Image', accessor: 'profilePictureThumbnail', Type: 'Image' },
    { Header: 'Museum Name', accessor: 'name' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Email', accessor: 'contactEmail' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Website', accessor: 'website' },
    { Header: 'Opening Hours', accessor: 'openingHours' },
    { Header: 'Description', accessor: 'description' },
    {
      Header: 'Status',
      accessor: 'isVerified',
      body: ({ row }: { row: any }) => (
        <StatusBadge isVerified={row.isVerified} />
      ),
    },
  ];

  const actions = [
    { title: 'Verify', onClick: handleView },
    { title: 'Delete', onClick: handleDelete },
  ];

  return (
    <div>
      <CustomTable
        action={actions}
        title="Museums"
        openForm={false}
        columns={columns}
        btnTxt="Upload Artwork"
        loading={isMuseumLoading}
        data={data?.museums || []}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        totalPages={totalPages}
        error={error}
        handleVerify={batchVerify}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={handleDeleteConfirm}
          onCancel={() => setModalOpen(false)}
          title="Do you want to delete this Museum?"
        />
      )}
    </div>
  );
};

export default withPaginatedQuery(MuseumsDashboard, {
  queryFn: userService.getMuseums,
  queryKey: [CACHE_KEYS.MUSEUMS],
});
