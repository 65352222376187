import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { artworkDto } from 'dto';
import CustomForm from '../forms/ZenForm';
import Button from 'components/UiComponents/Button';

const BaseArtWorkForm = ({ submitHandler, defaultValues }) => {
  const { t } = useTranslation();
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(artworkDto.artworkSchema(t)),
  });
  const [key, setKey] = useState(false);

  const INPUTS = useMemo(() => {
    return artworkDto.artworkInputs({ t });
  }, [t]);

  const onSubmit = async (data) => {
    await submitHandler(data);
    reset();
    setKey((p) => !p);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CustomForm
        key={key ? 'create' : 'created'}
        inputs={INPUTS}
        control={control}
        direction={'vertical'}
        errors={errors}
      />
      <div className="text-right">
        <Button text="Submit" variant="primary" type="submit" />
      </div>
    </form>
  );
};

export default BaseArtWorkForm;
