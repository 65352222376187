import BaseArtWorkForm from './BaseForm';

const defaultValues = {
  title: '',
  description: '',
  medium: '',
  dimensions: '',
  genre: '',
  material: '',
  orientation: 'landscape',
  financialStatus: 'for-sale',
  price: 0,
  image: null,
  tags: [],
};

const CreateArtWork = ({ hanldeSubmit }) => {
  return (
    <BaseArtWorkForm
      submitHandler={hanldeSubmit}
      defaultValues={defaultValues}
    />
  );
};

export default CreateArtWork;
