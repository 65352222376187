import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TabsProps {
  type?: string;
  name?: string;
  tabs?: string[];
  location?: string;
  profileURL?: string;
  fallbackUrl?: string;
  renderTabContent?: (activeTab: string) => JSX.Element | null; // Type for renderTabContent
}

const Tabs: React.FC<TabsProps> = ({
  name = 'name',
  profileURL = '',
  fallbackUrl = '',
  location = 'string',
  tabs = ['events', 'allWorks', 'biography'],
  renderTabContent,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className="p-4">
      {/* Profile  */}
      <div className="flex items-center space-x-4">
        <div
          className="w-12 h-12 rounded bg-primary"
          style={{
            backgroundImage: `url(${profileURL || fallbackUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div>
          <h1 className="text-2xl font-semibold">{name}</h1>
          <p className="text-gray-500 flex items-center">{location}</p>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="mt-4 border-t border-b border-primary">
        <nav className="flex justify-around space-x-8 py-2">
          {tabs.map((tabKey: string) => (
            <button
              key={tabKey}
              onClick={() => setActiveTab(tabKey)}
              className={`text-gray-600 font-semibold hover:text-gray-900 ${
                activeTab === tabKey
                  ? 'font-bold border-b-2 border-primary'
                  : ''
              }`}
            >
              {t(tabKey)}
            </button>
          ))}
        </nav>
      </div>

      <div className="mt-4">{renderTabContent?.(activeTab)}</div>
    </div>
  );
};

export default Tabs;
