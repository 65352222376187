import { CACHE_KEYS } from 'common';
import { useQuery } from '@tanstack/react-query';
import { getArtwork, getFilters } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';

type ArtworkItem = {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
};

type InputData = Record<string, ArtworkItem[]>;

type TransformedItem = {
  label: string;
  options: string[];
};

const filters = [
  { label: 'allAuthors', value: 'artists', key: 'uploaderTypes' },
  { label: 'allGalleries', value: 'galleries', key: 'uploaderTypes' },
  { label: 'All Museums', value: 'museums', key: 'uploaderTypes' },
];

const transformData = (data: InputData): TransformedItem[] => {
  return Object.entries(data || {}).map(([key, items]) => ({
    label: key,
    options: items.map((item) => item.title),
  }));
};

const Collections = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => getFilters(),
    queryKey: [CACHE_KEYS.GET_FILTERS],
  });

  const dynamicFilters = transformData(data?.data?.records);

  return (
    <PageTemplate
      accessor="artworks"
      heading="collections"
      fetchItems={getArtwork}
      queryKey={CACHE_KEYS.ARTWORKS}
      filters={[...filters, ...dynamicFilters]}
    />
  );
};

export default Collections;
