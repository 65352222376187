export function createFormData(obj) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    // Handle arrays and other special cases if needed
    if (Array.isArray(value)) {
      value.forEach((item, index) => formData.append(`${key}[${index}]`, item));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
}

export function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}

const permissions = {
  user: ['comment on art'],
  admin: [
    'view:dashboard',
    'view:dashboardUsers',
    'view:dashboardArtwork',
    'view:dashboardArtist',
    'view:dashboardEvents',
    'view:dashboardGallery',
    'view:dashboardMuseums',
    'view:dashboardStatistics',
  ],
  artist: ['view:dashboard', 'view:dashboardArtwork', 'view:dashboardEvents'],
  gallery: ['view:dashboard', 'view:dashboardArtwork', 'view:dashboardEvents'],
  museum: ['view:dashboard', 'view:dashboardArtwork', 'view:dashboardEvents'],
};

export function hasPermission(userType, permission) {
  if (!permission) {
    return true;
  }

  return permissions[userType]?.includes(permission) || false;
}

export * as commonUtils from './common';


export function getQueryParam(key) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
}