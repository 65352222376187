import DropFileInput from './CustomFileInput';
import CustomOptionsInput from './CustomOptionsInput';
import CustomTextInput from './CustomTextInput';

const CustomForm = ({ inputs, control, errors, direction }) => {
  return (
    <div className="grid gap-4 mb-4 max-w-screen-md">
      {inputs.map((input) => {
        let InputComponent;

        if (
          input.type === 'text' ||
          input.type === 'email' ||
          input.type === 'multiline' ||
          input.type === 'number' ||
          input.type === 'date' ||
          input.type === 'time' ||
          input.type === 'password'
        ) {
          InputComponent = (
            <CustomTextInput
              {...input}
              control={control}
              errorFeedback={errors[input.name]}
            />
          );
        } else if (input.type === 'file') {
          InputComponent = (
            <DropFileInput
              control={control}
              {...input}
              errorFeedback={errors[input.name]}
            />
          );
        } else {
          InputComponent = (
            <CustomOptionsInput
              control={control}
              {...input}
              errorFeedback={errors[input.name]}
            />
          );
        }

        return (
          <div
            key={input.name}
            className={`${
              direction === 'vertical' ? 'col-span-full' : 'col-span-1'
            }`}
          >
            {InputComponent}
          </div>
        );
      })}
    </div>
  );
};

export default CustomForm;
