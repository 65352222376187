import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, isLogin }) {
  if (!isLogin) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default PrivateRoute;
