import { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';

const RegistrationChart = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [timeFrame, setTimeFrame] = useState('year');


  const dataSets = {
    year: {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      data: [50, 55, 53, 54, 56, 52, 53, 54, 56, 57, 58, 60],
    },
    month: {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      data: [55, 53, 54, 56],
    },
    week: {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      data: [54, 52, 56, 55, 53, 57, 58],
    },
  };

  const createChart = (labels, data) => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            type: 'bar',
            label: 'Registrations',
            data: data,
            backgroundColor: 'rgba(211, 211, 211, 0.5)',
            borderRadius: 10,
            borderSkipped: false,
          },
          {
            type: 'line',
            label: 'Trend',
            data: data,
            borderColor: '#8c8c8c',
            borderWidth: 2,
            tension: 0.3,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            max: 100,
            grid: {
              display: true,
              color: '#f0f0f0',
            },
            ticks: {
              stepSize: 20,
              callback: (value) => `${value}%`,
            },
          },
        },
      },
    });
  };

  // Update chart data whenever timeFrame changes
  useEffect(() => {
    const { labels, data } = dataSets[timeFrame];
    createChart(labels, data);
  }, [timeFrame]);

  return (
    <div>
      <div className="flex justify-between items-center mb-4 mx-4">
        <h2 className="text-lg font-bold">{t('registrations_over_time')}</h2>
        <select
          className="border border-gray-300 rounded-md px-3 py-1"
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          <option value="year">This year</option>
          <option value="month">This month</option>
          <option value="week">This week</option>
        </select>
      </div>
      <canvas ref={chartRef} style={{ minHeight: '170px', maxHeight:"200px", width: '100%' }} />
    </div>
  );
};

export default RegistrationChart;
