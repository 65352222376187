import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const initialLocation = {
  lat: 44.90346026147556,
  lng: -93.27596928956784,
};

const MapWithMarker = ({
  location = initialLocation,
  style = mapContainerStyle,
}) => {

  const {t} = useTranslation()
 
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey:  "AIzaSyAw-yV2UPJIHcQ8lnhaWPKmL5e5EB4hAXI"
  });

  useEffect(() => {}, []);
  if (!isLoaded) return <div>{t('loading')}...</div>;

  return (
    <GoogleMap mapContainerStyle={style} center={location} zoom={10}>
      <MarkerF position={location} />
    </GoogleMap>
  );
};
export default MapWithMarker;
