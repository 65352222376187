import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import logo from '../../logo.svg';
import useUserStore from '../../store';
import { hasPermission } from '../../utils';
import LanguageSelector from '../UiComponents/LanguageSelector';

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: #f2efe8;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  height: 40px;
`;

const AuthSection = styled.div`
  display: flex;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Nav = styled.nav`
  gap: 20px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #202020;
  font-weight:600;
  margin: 3px 15px;
  font-size: 18px;
  &:hover {
    color: #a89f91;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #a89f91;
  margin: 8px 40px;
`;

const AuthLink = styled(Link)`
  text-decoration: none;
  color: #202020;
  font-weight:600;
  font-size: 16px;
  &:hover {
    color: #a89f91;
  }
`;

const Hamburger = styled.div`
  display: none;
  width: 30px;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const Line = styled.div`
  width: 25px;
  height: 3px;
  background: #202020;
  margin: 3px 0;
`;

const MobileNav = styled.nav<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100vh;
  background: #c2beb4;
  padding: 10px 20px;
  margin-top: 10px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Header: React.FC = () => {
  const { isAuthenticated, userType } = useUserStore();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    // if (isOpen) {
    //   document.body.style.overflow = 'auto';
    // } else {
    //   document.body.style.overflow = 'hidden';
    // }
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer className="container mx-auto">
      <TopSection>
        <Link to="/">
          <Logo src={logo} alt="Arthub Logo" />
        </Link>
        <div className="flex">
          <AuthSection className="hidden md:block mr-3">
            {isAuthenticated ? (
              <AuthLink to="/account">{t('account')}</AuthLink>
            ) : (
              <>
                <AuthLink to="/login">{t('login')}</AuthLink>
                <AuthLink to="/register">{t('register')}</AuthLink>
              </>
            )}
          </AuthSection>
          <LanguageSelector />
        </div>
        <Hamburger onClick={toggleDropdown}>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              className="-ml-1 "
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          ) : (
            <>
              <Line />
              <Line />
              <Line />
            </>
          )}
        </Hamburger>
      </TopSection>
      <Divider />
      <BottomSection>
        <Nav className="hidden md:block min-w-[70%]">
          <NavLink to="/">{t('home')}</NavLink>
          <NavLink to="/collections">{t('collections')}</NavLink>
          <NavLink to="/galleries">{t('galleries')}</NavLink>
          <NavLink to="/museums">{t('museums')}</NavLink>
          <NavLink to="/events">{t('events')}</NavLink>
          <NavLink to="/artists">{t('artists')}</NavLink>
          <NavLink to="/about">{t('about')}</NavLink>
        </Nav>
        {hasPermission(userType, 'view:dashboard') ? (
          <span className="hidden md:block">
            <NavLink to="/dashboard">{t('dashboards')}</NavLink>
          </span>
        ) : null}

        <MobileNav $isOpen={isOpen}>
          {hasPermission(userType, 'view:dashboard') ? (
            <NavLink to="/dashboard" onClick={() => setIsOpen(false)}>
              {t('dashboards')}
            </NavLink>
          ) : null}
          <NavLink to="/" onClick={() => setIsOpen(false)}>
            {t('home')}
          </NavLink>
          <hr />
          <NavLink
            className="mt-1"
            to="/events"
            onClick={() => setIsOpen(false)}
          >
            {t('events')}
          </NavLink>
          <hr />
          <NavLink
            className="mt-1"
            to="/collections"
            onClick={() => setIsOpen(false)}
          >
            {t('collections')}
          </NavLink>
          <hr />
          <NavLink
            className="mt-1"
            to="/artists"
            onClick={() => setIsOpen(false)}
          >
            {t('artists')}
          </NavLink>
          <hr />
          <NavLink
            className="mt-1"
            to="/galleries"
            onClick={() => setIsOpen(false)}
          >
            {t('galleries')}
          </NavLink>
          <hr />
          <NavLink
            className="mt-1"
            to="/museums"
            onClick={() => setIsOpen(false)}
          >
            {t('museums')}
          </NavLink>
          <hr />
          <NavLink
            className="mt-1"
            to="/about"
            onClick={() => setIsOpen(false)}
          >
            {t('about')}
          </NavLink>
          <hr />

          <AuthSection className="111 hidden md:block">
            {isAuthenticated ? (
              <AuthLink to="/account">{t('account')}</AuthLink>
            ) : (
              <>
                <AuthLink to="/login">{t('login')}</AuthLink>
                <AuthLink to="/register">{t('register')}</AuthLink>
              </>
            )}
          </AuthSection>
        </MobileNav>
      </BottomSection>
    </HeaderContainer>
  );
};

export default Header;
