import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CACHE_KEYS } from 'common';

import useMuseumStore from 'store/museum';
import author from '../../assets/museum-bg.png';
import Tabs from 'components/UiComponents/Tabs';
import Loader from 'components/UiComponents/Loader';
import Reviews from 'components/UiComponents/Reviews';
import Biography from 'components/UiComponents/Biography';
import { getArtwork, getEvents } from 'services/user.service';
import PaginatedItems from 'components/UiComponents/PaginatedItems';

const MuseumsDetails = () => {
  const { museumDetails, loading, fetchMuseumById } = useMuseumStore();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchMuseumById(id);
    }
  }, [id]);

  const [imageSrc, setImageSrc] = useState(
    museumDetails?.backgroundPicture || author,
  );
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageError = () => {
    setImageSrc(author);
    setIsImageLoaded(true);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const renderTabContent = (activeTab) => {
    switch (activeTab) {
      case 'review':
        return <Reviews />;
      case 'events':
        return (
          <PaginatedItems
            isEventType
            accessor="events"
            fetchItems={getEvents}
            params={{ organizerId: id }}
            queryKey={[CACHE_KEYS.EVENTS, id]}
          />
        );
      case 'allWorks':
        return (
          <PaginatedItems
            isArtType
            accessor="artworks"
            fetchItems={getArtwork}
            params={{ museumId: id }}
            queryKey={[CACHE_KEYS.ARTWORKS, id]}
          />
        );
      case 'about':
        return (
          <Biography
            user={{
              phone: museumDetails?.phone,
              country: museumDetails?.country,
              website: museumDetails?.website,
              birthYear: museumDetails?.birthYear,
              biography: museumDetails?.biography,
              biography: museumDetails?.description,
              isVerified: museumDetails?.isVerified,
              socialMedia: museumDetails?.socialMedia,
              achievements: museumDetails?.achievements,
              phoneVerified: museumDetails?.phoneVerified,
              profilePicture: museumDetails?.profilePicture,
            }}
          />
        );
      default:
        return <p>No Data Found</p>;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-52">
        <div>
          <Loader /> loading...
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <div className="flex flex-col md:flex-row gap-4 md:gap-10 mb-3">
        <div className="w-full relative">
          <div
            style={{
              minHeight: '300px',
              maxHeight: '500px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${isImageLoaded ? imageSrc : author})`,
            }}
            className={`md:min-h-[400px]`}
          >
            <img
              src={imageSrc}
              alt="gallery"
              className="hidden" // Hide the <img>, only use it for loading/error detection
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
        </div>
      </div>

      <div className=" md:px-20">
        <Tabs
          profileURL={
            museumDetails?.profilePictureThumbnail ||
            museumDetails?.profilePicture
          }
          type="gallery"
          name={museumDetails?.name}
          location={museumDetails?.address}
          renderTabContent={renderTabContent}
          tabs={['allWorks', 'events', 'about']}
        />
        <br />

        <div className="my-8" />
      </div>
    </div>
  );
};

export default MuseumsDetails;
