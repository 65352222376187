import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SidebarFilters = ({
  filters,
  heading,
  initExpand = {},
  setSelectedFilters,
  selectedFilters = {},
  onFilterChange = (e) => {},
}) => {
  const { t } = useTranslation();

  const [expandedFilters, setExpandedFilters] = useState(initExpand);

  const toggleExpand = (label) => {
    setExpandedFilters((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const handleCheckboxChange = (filter, option, isBoolean, event) => {
    const isChecked = event.target.checked;
    let newFilter = { ...selectedFilters };

    if (option) {
      if (isChecked) {
        if (newFilter[filter]) {
          if (!newFilter[filter].includes(option)) {
            newFilter[filter].push(option);
          }
        } else {
          newFilter[filter] = [option];
        }
      } else {
        if (newFilter[filter]) {
          newFilter[filter] = newFilter[filter].filter(
            (value) => value !== option,
          );

          if (newFilter[filter].length === 0) {
            delete newFilter[filter];
          }
        }
      }

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      setSelectedFilters(newFilter);

      return;
    }

    if (isChecked) {
      if (newFilter[filter?.key]) {
        if (!newFilter[filter?.key].includes(filter?.value)) {
          newFilter[filter?.key].push(filter?.value);
        }
      } else {
        newFilter[filter?.key] = [filter?.value];
      }
    } else {
      if (newFilter[filter?.key]) {
        newFilter[filter?.key] = newFilter[filter?.key].filter(
          (value) => value !== filter?.value,
        );
        if (newFilter[filter?.key].length === 0) {
          delete newFilter[filter?.key];
        }
      }
    }

    if (onFilterChange) {
      onFilterChange(newFilter);
    }

    setSelectedFilters(newFilter);
  };

  return (
    <aside className="w-64 p-4">
      <h1 className="font-cormorant font-bold text-2xl leading-[41.17px] mb-4">
        {heading}
      </h1>

      {filters?.map((filter) => {
        return (
          <div key={filter.label} className="mb-3 text-xl ">
            {filter.options ? (
              <>
                <div
                  onClick={() => toggleExpand(filter.label)}
                  className="flex pt-3 px-4 cursor-pointer justify-between items-center border-t border-black"
                >
                  <h2 className="font-semibold">{t(filter.label)}</h2>
                  <button>
                    {expandedFilters[filter.label] ? (
                      <svg
                        width="24"
                        height="2"
                        viewBox="0 0 24 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.0156 1C23.0156 1.19891 22.9415 1.38968 22.8097 1.53033C22.6778 1.67098 22.499 1.75 22.3125 1.75H1.6875C1.50102 1.75 1.32218 1.67098 1.19032 1.53033C1.05845 1.38968 0.984375 1.19891 0.984375 1C0.984375 0.801088 1.05845 0.610323 1.19032 0.46967C1.32218 0.329018 1.50102 0.25 1.6875 0.25H22.3125C22.499 0.25 22.6778 0.329018 22.8097 0.46967C22.9415 0.610323 23.0156 0.801088 23.0156 1Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.7812 11C21.7812 11.1243 21.7319 11.2435 21.644 11.3315C21.556 11.4194 21.4368 11.4688 21.3125 11.4688H11.4688V21.3125C11.4688 21.4368 11.4194 21.556 11.3315 21.644C11.2435 21.7319 11.1243 21.7812 11 21.7812C10.8757 21.7812 10.7565 21.7319 10.6685 21.644C10.5806 21.556 10.5312 21.4368 10.5312 21.3125V11.4688H0.6875C0.56318 11.4688 0.443951 11.4194 0.356044 11.3315C0.268136 11.2435 0.21875 11.1243 0.21875 11C0.21875 10.8757 0.268136 10.7565 0.356044 10.6685C0.443951 10.5806 0.56318 10.5312 0.6875 10.5312H10.5312V0.6875C10.5312 0.56318 10.5806 0.443951 10.6685 0.356044C10.7565 0.268136 10.8757 0.21875 11 0.21875C11.1243 0.21875 11.2435 0.268136 11.3315 0.356044C11.4194 0.443951 11.4688 0.56318 11.4688 0.6875V10.5312H21.3125C21.4368 10.5312 21.556 10.5806 21.644 10.6685C21.7319 10.7565 21.7812 10.8757 21.7812 11Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {expandedFilters[filter.label] && (
                  <ul className="mt-2 ml-4 mr-3">
                    {filter.options.map((option) => (
                      <li key={option} className="mb-2">
                        <label className="inline-flex items-center justify-between w-full cursor-pointer">
                          <span className="ml-2 ">{t(option)}</span>
                          <input
                            type="checkbox"
                            checked={
                              selectedFilters[filter.label]?.includes(option) ||
                              false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                filter.label,
                                option,
                                false,
                                e,
                              )
                            }
                            className="form-checkbox cursor-pointer text-gray-500 focus:ring-gray-600 w-4 h-4 rounded-none"
                          />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <label className="inline-flex items-center justify-between w-full cursor-pointer ">
                <span className="ml-2 font-semibold">{t(filter.label)}</span>
                <input
                  type="checkbox"
                  checked={
                    selectedFilters[filter.key]?.includes(filter.value) || false
                  }
                  onChange={(e) => handleCheckboxChange(filter, null, true, e)}
                  className="form-checkbox w-4 h-4 rounded-none mr-3 cursor-pointer"
                />
              </label>
            )}
          </div>
        );
      })}
    </aside>
  );
};

export default SidebarFilters;
