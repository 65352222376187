import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGalleryStore from '../store/gallery';
import Loader from 'components/UiComponents/Loader';
import GalleryDetails from '../components/Gallery/Details';

function GalleryDetail() {
  const { galleryDetails, fetchGalleryById, galleryLoading } =
    useGalleryStore();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchGalleryById(id);
    }
  }, [id]);

  if (galleryLoading) {
    return (
      <div className="flex justify-center items-center p-52">
        <div>
          <Loader /> loading...
        </div>
      </div>
    );
  }
  return <GalleryDetails galleryDetails={galleryDetails} />;
}

export default GalleryDetail;
