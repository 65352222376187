import { NotificationsSvg } from '../../assets/SVGs';
import { Link } from 'react-router-dom';

const Notifications = () => {
  const notifications = [
    {
      id: 1,
      title: 'We’ve just released a new feature',
      description:
        'Lorem ipsum dolor sit amet consectetur adipiscing elit. Aliquam pundefinedtur, ipsum similique veniam.',
    },
    {
      id: 2,
      title: 'New updates are available',
      description: 'Check out the latest updates to enhance your experience.',
    },
    {
      id: 3,
      title: 'Maintenance scheduled',
      description:
        'Scheduled maintenance will occur on Saturday. Please plan accordingly.',
    },
    {
      id: 4,
      title: 'System performance improvement',
      description:
        'We have made some changes to improve system performance. Enjoy a smoother experience!',
    },
    {
      id: 5,
      title: 'Maintenance scheduled',
      description:
        'Scheduled maintenance will occur on Saturday. Please plan accordingly.',
    },
    {
      id: 6,
      title: 'System performance improvement',
      description:
        'We have made some changes to improve system performance. Enjoy a smoother experience!',
    },
  ];

  return (
    <div>
      <div className="space-y-4 max-h-[80vh] overflow-auto">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="border border-[#A89F91] rounded-lg p-4 bg-white"
          >
            <div className="flex gap-3">
              <div>
                <NotificationsSvg />
              </div>
              <div>
                <h3 className="font-semibold">{notification.title}</h3>
                <p className="text-muted-foreground mb-2">
                  {notification.description}
                </p>
                <div className="flex space-x-4 items-center">
                  <Link to="" className="font-semibold hover:underline">
                    Learn more
                  </Link>
                  <Link
                    to=""
                    className="text-[#A89F91] font-semibold hover:underline"
                  >
                    View changes
                  </Link>
                  <Link to="">
                    <svg
                      width="14"
                      height="14"
                      className="mt-1 "
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.1665 7H12.8332M12.8332 7L6.99984 1.16667M12.8332 7L6.99984 12.8333"
                        stroke="#A89F91"
                        stroke-width="1.66667"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
