import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useArtistStore from 'store/artists';
import AuthorsDetails from '../components/Authors/Details';

function AuthorsDetail() {
  const { id } = useParams();
  const { fetchArtistById, authorDetails } = useArtistStore();

  useEffect(() => {
    if (id) {
      fetchArtistById(id);
    }
  }, [id]);

  return <AuthorsDetails authorDetails={authorDetails} />;
}

export default AuthorsDetail;
