import { useState } from 'react';
import toast from 'react-hot-toast';
import queryClient from 'config/query';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import { adminService, userService } from 'services';
import CustomTable from './CustomTable';
import useArtistStore from '../../store/artists';
import ConfirmationModal from './ConfirmationModal';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import { showMutationError, showSuccessMessage } from 'utils/common';

type ArtistsDashboardProps = {
  data: any;
  search: string;
  totalPages: number;
  isLoading: boolean;
  paginationModel: { page: number };
  setSearch: (value: string) => void;
  handlePageChange: (page: number) => void;
};

type Artist = {
  id: number;
  name: string;
  email: string;
  lastActive: string;
  totalArtworks: string;
};

const StatusBadge = ({ isVerified }: { isVerified: boolean }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`text-center  whitespace-nowrap px-2 py-1 rounded-full ${
        isVerified ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
      }`}
    >
      {t(isVerified ? 'verified' : 'notVerified')}
    </div>
  );
};

const ArtistsDashboard = ({
  data,
  isLoading,
  totalPages,
  paginationModel,
  handlePageChange,
  search: searchQuery,
  setSearch: setSearchQuery,
}: ArtistsDashboardProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null);

  const { error } = useArtistStore();

  const { isPending: isDeleting, mutateAsync: deleteArtist } = useMutation({
    mutationFn: (id: any) => adminService.deleteArtistById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.ARTISTS],
      });
      toast.success('Artist deleted successfully');
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const { isPending: loadingVerify, mutateAsync: verifyArtists } = useMutation({
    mutationFn: (ids: any) => adminService.verifyArtists(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.ARTISTS],
      });
    },
  });

  const batchVerify = async (ids: any, cb?: any) => {
    try {
      if (!ids.length) return;
      await verifyArtists(ids);
      showSuccessMessage(t);

      cb && cb?.();
    } catch (error) {
      showMutationError(error);
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedArtist) {
      deleteArtist(selectedArtist.id);
      setModalOpen(false);
    }
  };

  const handleApprove = (artist: Artist) => {
    if (!artist.id) return;
    batchVerify([artist.id]);
  };

  const handleDelete = (artist: Artist) => {
    setSelectedArtist(artist);
    setModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const columns = [
    {
      Header: 'Image',
      accessor: 'profilePictureThumbnail',
      Type: 'Image',
    },
    { Header: 'Name', accessor: 'firstName' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'City', accessor: 'city' },
    { Header: 'Birth Year', accessor: 'birthYear' },
    { Header: 'Biography', accessor: 'biography' },
    { Header: 'Achievements', accessor: 'achievements' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Verified', accessor: 'isVerified' },
    { Header: 'Website', accessor: 'website' },
    {
      Header: 'Status',
      accessor: 'isVerified',
      body: ({ row }: { row: any }) => (
        <StatusBadge isVerified={row.isVerified} />
      ),
    },
  ];

  const actions = [
    { title: 'Approve', onClick: handleApprove },
    { title: 'Delete', onClick: handleDelete },
  ];

  return (
    <div>
      <CustomTable
        title="artists"
        action={actions}
        loading={isLoading}
        columns={columns}
        openForm={false}
        totalPages={totalPages}
        btnTxt="upload_artwork"
        searchQuery={searchQuery}
        data={data?.artists || []}
        handleSearch={handleSearch}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        error={error}
        handleVerify={batchVerify}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={handleDeleteConfirm}
          onCancel={() => setModalOpen(false)}
          title={`Do you want to delete artist ${selectedArtist?.name}?`}
        />
      )}
    </div>
  );
};

export default withPaginatedQuery(ArtistsDashboard, {
  queryFn: userService.getArtist,
  queryKey: [CACHE_KEYS.ARTISTS],
});
