import { useTranslation } from 'react-i18next';

const ReviewSection = ({ name, location, description }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-start  text-secondary p-6 border border-[#ede9e0]">
      <div className="flex-1">
        <div className="flex flex-wrap justify-between items-center">
          <div className="mb-2 flex">
            <div className="w-16 h-16 bg-primary rounded-md mr-6" />
            <div>
              <h4 className="text-2xl font-semibold">{name}</h4>
              <p className="text-lg ">{location}</p>
            </div>
          </div>

          <div className="pt-3">
            <button className="bg-primary text-white px-8 py-1 text-xl">
              {t('readMore')}
            </button>
          </div>
        </div>
        <p className=" mb-4 leading-relaxed">{description}</p>
      </div>
    </div>
  );
};

export default ReviewSection;
