import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

interface ButtonProps {
  text?: string;
  color?: string;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
  customStyleOnly?: boolean;
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color = '',
  style = {},
  text = 'Click',
  size = 'medium',
  className = '',
  disabled = false,
  onClick = () => {},
  variant = 'primary',
  customStyleOnly = false,
}) => {
  const baseClasses = customStyleOnly
    ? className
    : `py-1 px-2 focus:outline-none shadow rounded-md transition-all duration-300 ease-in-out ${className}`;

  const sizeClasses = {
    small: 'px-4 py-0 text-sm',
    medium: 'px-6 py-1 text-base',
    large: 'px-8 py-2 text-lg',
  };

  const variantClasses = {
    primary: 'bg-primary text-Tlight hover:bg-primary-dark focus:ring-primary',
    secondary:
      'bg-secondary text-Tlight hover:bg-secondary-dark focus:ring-secondary',
  };

  const buttonClasses = `${baseClasses} ${
    sizeClasses[size] || sizeClasses.medium
  } ${variantClasses[variant] || variantClasses.primary} ${
    color ? `bg-${color}` : ''
  } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;

  return (
    <button
      style={style}
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
    >
      {text}
      {children}
    </button>
  );
};

export default Button;
