import { useEffect, useState } from 'react';
import Viewer from 'react-viewer';
import { CACHE_KEYS } from 'common';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../UiComponents/Loader';
import ReviewSection from '../UiComponents/Review';
import useArtworkStore from '../../store/artWorks';
import RecentItems from 'components/UiComponents/RecentItems';
import { getArtwork, getGalleries } from 'services/user.service';

const PaintingDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);

  const { data: recentArtworks } = useQuery({
    queryKey: [CACHE_KEYS.RECENT_ARTWORK],
    queryFn: () => getArtwork({ limit: 4 }),
  });

  const { data: recentGalleries } = useQuery({
    queryKey: [CACHE_KEYS.RECENT_GALLERIES],
    queryFn: () => getGalleries({ limit: 4 }),
  });

  const {
    loading,
    artDetails,
    // recentArtworks,
    fetchArtworkById,
  } = useArtworkStore();

  useEffect(() => {
    if (id) {
      fetchArtworkById(id);
    }
  }, [id]);

  const openViewer = () => {
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  if (loading) {
    return (
      <div className="text-center mt-20">
        <Loader /> <p>{t('loading')}...</p>
      </div>
    );
  }

  if (!artDetails) {
    return <div className="flex justify-center  mt-20">Data not found.</div>;
  }
  const imageUrl = artDetails?.imageUrl;

  return (
    <div className="min-h-screen px-4 md:px-10 lg:px-20">
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-10">
        <div
          style={{
            backgroundImage: `url(${artDetails?.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          onClick={openViewer}
          className="w-full lg:w-2/3  h-[400px] lg:h-[700px] cursor-pointer"
        />
        <Viewer
          visible={visible}
          defaultScale={2}
          onClose={closeViewer}
          images={[{ src: imageUrl, alt: 'Event Image' }]}
        />
        <div className="w-full lg:w-1/3">
          <h1 className="text-2xl lg:text-3xl font-serif italic mb-5">
            {t(artDetails.title || '- -')}
          </h1>
          <h2 className="text-3xl lg:text-4xl font-serif italic mb-8">
            {t(artDetails.description || '- -')}
          </h2>

          <h1 className="text-2xl lg:text-3xl font-serif italic mb-5">
            {t(artDetails?.Artist?.pseudonym || '- -')}
          </h1>

          <div className="flex flex-wrap gap-3 lg:space-x-5 mb-8">
            <button className="bg-primary text-white px-6 lg:px-8 py-1 text-lg lg:text-xl">
              {t(artDetails.ArtworkType?.title || '- -')}
            </button>
            <button className="bg-primary text-white px-6 lg:px-8 py-1 text-lg lg:text-xl">
              {t('cubism')}
            </button>
          </div>

          <div className="mb-8 text-primary text-sm lg:text-base">
            <p>
              {t('culturalHeritage')}
              {artDetails.culturalHeritage ? 'Yes' : 'No'}
            </p>
            <p>{t('legend')}</p>

            <div className="mt-4">
              <p>
                <strong className="text-secondary min-w-32 inline-block">
                  {t('material')}:
                </strong>{' '}
                {t(artDetails.ArtworkMaterial?.title)}
              </p>
              <p>
                <strong className="text-secondary min-w-32 inline-block">
                  {t('paintingType')}:
                </strong>{' '}
                {t(artDetails.ArtworkPaintType?.title)}
              </p>
              <p>
                <strong className="text-secondary min-w-32 inline-block">
                  {t('size')}:
                </strong>{' '}
                305 x 555 см
              </p>
              <p>
                <strong className="text-secondary min-w-32 inline-block">
                  {t('orientation')}:
                </strong>{' '}
                {}
                {t(artDetails.ArtworkOrientation?.title)}
              </p>
            </div>
          </div>

          <div className="text-secondary mb-10 text-sm lg:text-base">
            <h3 className="font-semibold mb-2">{t('aboutWork')}</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur. Vitae arcu feugiat integer
              consequat euismod ipsum pretium. Ultrices posuere maecenas non
              aliquet
            </p>
            <p className="mt-2">
              Suspendisse eget purus sagittis ullamcorper at id. Orci fermentum
              etiam nibh faucibus sit.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-10 space-y-10">
        <ReviewSection
          name={t('artistName', { name: 'Some Random Artist' })}
          location={t('Dynamiclocation', { location: 'Ташкент' })}
          description={t('reviewDescription')}
        />
        <div className="cards-with-heading-and-button">
          <RecentItems
            isArtWork={true}
            descriptionKey=""
            buttonTextKey="seeAll"
            link="/collections?allAuthors=true"
            items={recentArtworks?.data?.artworks || []}
            titleKey={t('otherWorksBy', { author: 'Some Artist' })}
          />
        </div>
        <div className="pt-10 pb-16">
          <RecentItems
            isGalleryType
            descriptionKey=""
            buttonTextKey="seeAll"
            link="/collections?allGalleries=true"
            items={recentGalleries?.data?.galleries || []}
            titleKey={t('otherWorksInGallery', { gallery: 'Some Gallery' })}
          />
        </div>
      </div>
    </div>
  );
};

export default PaintingDetails;
