import React from 'react';

interface SocialMedia {
  youtube?: string;
  facebook?: string;
  instagram?: string;
  [key: string]: string | undefined;
}

interface Artist {
  id: number;
  phone?: string;
  country?: string;
  website?: string;
  birthYear?: number;
  biography?: string;
  isVerified?: boolean;
  achievements?: string;
  phoneVerified?: boolean;
  profilePicture?: string;
  socialMedia?: SocialMedia;
}

const Biography: React.FC<{ user?: Artist }> = ({ user }) => {
  const getFieldValue = (value: string | number | undefined): string =>
    value ? value.toString() : '--';

  const fallbackUser: Artist = {
    id: 0,
    phone: undefined,
    country: '--',
    website: undefined,
    birthYear: undefined,
    biography: '--',
    isVerified: false,
    achievements: '--',
    phoneVerified: false,
    profilePicture: undefined,
    socialMedia: {},
  };

  const artist = user || fallbackUser;

  return (
    <div className="p-2 rounded-lg border border-[#dacfbe]">
      <div className="flex items-center justify-between ">
        <div>
          <h2 className="text-xl font-semibold">Biography</h2>
          <p className="text-gray-700">{getFieldValue(artist.biography)}</p>
        </div>
        <div>
          {artist.isVerified && (
            <span className="text-sm text-green-600 font-semibold">
              Verified
            </span>
          )}
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Achievements</h2>
        <p className="text-gray-700">{getFieldValue(artist.achievements)}</p>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Social Media</h2>
        <div className="flex space-x-4">
          {Object.entries(artist.socialMedia || {}).length > 0 ? (
            Object.entries(artist.socialMedia || {}).map(
              ([platform, link]) =>
                link && (
                  <a
                    key={platform}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </a>
                ),
            )
          ) : (
            <p className="text-gray-700">--</p>
          )}
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold">Contact</h2>
        <p className="text-gray-700">
          Phone: {getFieldValue(artist.phone)}{' '}
          {artist.phoneVerified ? '(Verified)' : ''}
        </p>
        <p className="text-gray-700">
          Website:{' '}
          {artist.website ? (
            <a
              href={artist.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 underline"
            >
              {artist.website}
            </a>
          ) : (
            '--'
          )}
        </p>
      </div>
    </div>
  );
};

export default Biography;
