import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logo from '../logo.svg';
import useUserStore from '../store';
import bg from '../assets/auth-bg.png';

const Forgot = () => {
  const location = useLocation();
  const { resetPassword, loading } = useUserStore((state) => state);

  const [email, setEmail] = useState(
    () => localStorage.getItem('registerEmail') || '',
  );
  const [token, setToken] = useState("");
  const [isVerified, setisVerified] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      // send API
      setisVerified(true);
    }
  }, [location]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error('Please enter a valid email address.');
      return;
    }

    try {
      await resetPassword(email, (error: any, message: string) => {
        if (error) {
          toast.error(error || 'Failed to send reset instructions.');
        } else {
          toast.success(
            message || 'Password reset instructions sent to your email.',
          );
          // localStorage.removeItem('registerEmail'); // Clean-up saved email if any
          // navigate('/login');
        }
      });
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
      console.error('Reset failed:', error);
    }
  };
  if (isVerified) {
    return <ResetPassword token={token} />;
  }

  return (
    <div className="flex items-center min-h-screen sm:bg-white">
      <div className=" flex items-center justify-center max-w-[1440px] mx-auto  bg-primaryLight my-4 overflow-hidden min-h-[600px] ">
        <div className="max-h-[750px] md:w-[50%] p-4 md:p-8   md:rounded-s-sm ">
          <div className="mb-6">
            <Link to="/">
              <img src={logo} alt="Arthub Logo" className="h-16" />
            </Link>
          </div>
          <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
            Restore password
          </h2>
          <p className="text-gray-600 mt-2 mb-8">Please enter your email.</p>
          <form onSubmit={handleReset}>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium"
              >
                Email
              </label>
              <input
                id="email"
                name="login"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 mt-8 bg-primary text-white font-semibold rounded-lg hover:bg-[#e9d0a9] transition duration-200"
            >
              {loading ? 'loading...' : 'Reset Password'}
            </button>
          </form>
          <div className="flex items-center my-6">
            <hr className="flex-grow border-primary" />
            <span className="mx-4 text-gray-500">OR</span>
            <hr className="flex-grow border-primary" />
          </div>

          <p className="mt-6 text-center text-gray-600">
            Remembered your password?{' '}
            <Link
              to="/login"
              className="text-gray-900 font-medium hover:underline"
            >
              Login
            </Link>
          </p>
          <p className="my-8 text-center text-gray-600">
            Don’t have an account yet?{' '}
            <Link
              to="/register"
              className="text-gray-900 font-medium hover:underline"
            >
              Register now
            </Link>
          </p>
        </div>
        <div className=" hidden md:block md:w-[50%] h-[100%] rounded-e-sm">
          <img
            src={bg}
            alt="art"
            className="h-[100%] max-h-[860px] rounded-e-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default Forgot;

const ResetPassword = ({ token }:any) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMsg('Passwords do not match!');
      return;
    }

    if(password?.length < 6){
      setErrorMsg('Password must be at least 6 characters long!');
      return;
    }

    setLoading(true);

    try {

      const response = await axios.post(`/api/users/reset-password/${token}`, {
        password,
      });
      // If successful:
      toast.success('Password reset successfully!');
      navigate('/login'); // Redirect to login page
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
      setErrorMsg('Error resetting password.');
    }

    setLoading(false);
  };

  return (
    <div className="flex items-center min-h-screen sm:bg-white">
      <div className=" flex items-center justify-center max-w-[1440px] mx-auto  bg-primaryLight my-4 overflow-hidden min-h-[600px] ">
        <div className="max-h-[750px] md:w-[50%] p-4 md:p-8   md:rounded-s-sm ">
          <div className="mb-6">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-16 " />
            </Link>
          </div>

          <h2 className="text-2xl font-semibold text-gray-800 ">
            Reset Your Password
          </h2>
          <p className="text-gray-600 mt-2 ">
            Enter your new password and confirm it to reset.
          </p>

          <form onSubmit={handleSubmit} className="mt-6">
            {errorMsg && (
              <div className="mb-4 p-3 text-red-800 bg-red-100 rounded-lg">
                {errorMsg}
              </div>
            )}

            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 font-medium"
              >
                New Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
                placeholder="Enter your new password"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 font-medium"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
                placeholder="Confirm your new password"
                required
              />
            </div>

            <div className="mt-6">
              <button
                type="submit"
                disabled={loading}
                className="w-full py-2 bg-primary text-white font-semibold rounded-lg hover:bg-primaryDark transition duration-200"
              >
                {loading ? "Loading.." : 'Reset Password'}
              </button>
            </div>
          </form>
          <div className="flex items-center my-6">
            <hr className="flex-grow border-primary" />
            <span className="mx-4 text-gray-500">OR</span>
            <hr className="flex-grow border-primary" />
          </div>
          <div className="mt-4 text-center">
            <small>
              <Link to="/login" className="  hover:underline">
                Back to Login
              </Link>
            </small>
          </div>
        </div>
        <div className=" hidden md:block md:w-[50%] h-[100%] rounded-e-sm">
          <img
            src={bg}
            alt="art"
            className="h-[100%] max-h-[860px] rounded-e-sm"
          />
        </div>
      </div>
    </div>
  );
};
