import { TFunction } from 'i18next';

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const CACHE_KEYS = {
  EVENTS: 'EVENTS',
  MUSEUMS: 'MUSEUMS',
  ARTISTS: 'ARTISTS',
  ARTWORKS: 'ARTWORKS',
  GET_USERS: 'GET_USERS',
  GALLERIES: 'GALLERIES',
  GET_FILTERS: 'GET_FILTERS',
  DELETE_USER: 'DELETE_USER',
  DELETE_EVENT: 'DELETE_EVENT',
  RECENT_EVENT: 'RECENT_EVENT',
  RECENT_MUSEUM: 'RECENT_MUSEUM',
  RECENT_ARTWORK: 'RECENT_ARTWORK',
  RECENT_ARTISTS: 'RECENT_ARTISTS',
  RECENT_GALLERIES: 'RECENT_GALLERIES',
  DASHBOARD_OVERVIEW: 'DASHBOARD_OVERVIEW',
  RECENT_ACTIVITY: 'RECENT_ACTIVITY',
  MODERATION_STATS: 'MODERATION_STATS',
  TIME_BASE_STATS: 'TIME_BASE_STATS',
  CREATE_EVENT: 'CREATE_EVENT',
};

export const ROUTES = {
  GET_USERS: '/api/users',
  GET_EVENT: '/api/events',
  GET_MUSEUM: '/api/museums',
  GET_ARTISTS: '/api/artists',
  CREATE_EVENT: '/api/events',
  GET_ARTWORK: '/api/artworks',
  GET_GALLERIES: '/api/galleries',
  CREATE_ARTWORK: '/api/artworks',
  GET_FILTERS: '/api/artworks/filters',
  DELETE_USER: (id: string) => `/api/user/${id}`,
  DELETE_EVENT: (id: string) => `/api/events/${id}`,
  DELETE_ARTIST: (id: string) => `/api/artist/${id}`,
  DELETE_MUSEUM: (id: string) => `/api/museum/${id}`,
  DELETE_GALLERY: (id: string) => `/api/gallery/${id}`,
  GET_DASHBOARD_OVERVIEW: '/api/dashboards/overview',
  GET_RECENT_ACTIVITY: '/api/dashboards/recent-activity',
  GET_MODERATION_STATS: '/api/dashboards/moderation-statistics',
  GET_TIME_BASE_STATS: '/api/dashboards/time-based-statistics',
  EDIT_ARTWORK: (id: string) => `/api/artworks/${id}`,
  VERIFY_MUSEUMS: '/api/museums/admin/batch-verification',
  VERIFY_ARTISTS: '/api/artists/admin/batch-verification',
  VERIFY_ARTWORKS: '/api/artworks/admin/batch-verification',
  VERIFY_GALLERIES: '/api/galleries/admin/batch-verification',
  TOGGLE_ARTWORK_VISIBILITY: (id: string) => `/api/artworks/${id}/visibility`,
  TOGGLE_EVENT_APPROVAL: (id: string) =>
    `api/events/admin/${id}/event-approval-toggle`,
};

export const TRANSLATION_KEYS = (t: TFunction) => ({
  landscape: t('artwork_landscape'),
  portrait: t('artwork_portrait'),
  'not-for-sale': t('artwork_notForSale'),
  'for-sale': t('artwork_forSale'),
  sold: t('artwork_sold'),
  public: t('public_visibility'),
  private: t('private_visibility'),
});

export const MODAL_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
} as const;

export const MODEL_INITIAL_STATE = {
  [MODAL_TYPE.CREATE]: false,
  [MODAL_TYPE.EDIT]: false,
  [MODAL_TYPE.DELETE]: false,
} as const;

export type IModalState = typeof MODEL_INITIAL_STATE;
