import { useState } from 'react';
import toast from 'react-hot-toast';
import queryClient from 'config/query';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import CustomTable from './CustomTable';
import useGalleryStore from '../../store/gallery';
import ConfirmationModal from './ConfirmationModal';
import { adminService, userService } from 'services';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import { showMutationError, showSuccessMessage } from 'utils/common';

type GalleriesDashboardProps = {
  isLoading: boolean;
  data: any;
  totalPages: number;
  paginationModel: { page: number };
  handlePageChange: (page: number) => void;
  search: string;
  setSearch: (value: string) => void;
};

const StatusBadge = ({ isVerified }: { isVerified: boolean }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`text-center px-2  whitespace-nowrap py-1 rounded-full ${
        isVerified ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
      }`}
    >
      {t(isVerified ? 'verified' : 'notVerified')}
    </div>
  );
};

const GalleriesDashboard = ({
  isLoading,
  data,
  totalPages,
  paginationModel,
  handlePageChange,
  search: searchQuery,
  setSearch: setSearchQuery,
}: GalleriesDashboardProps) => {
  const { t } = useTranslation();
  const { error } = useGalleryStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState<Record<
    string,
    any
  > | null>(null);

  const { isPending: isDeleting, mutateAsync: deleteGallery } = useMutation({
    mutationFn: (id: string) => adminService.deleteGalleryById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.GALLERIES],
      });
      toast.success('Gallery deleted successfully');
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const { isPending: loadingVerify, mutateAsync: verifyGalleries } =
    useMutation({
      mutationFn: (ids: any) => adminService.verifyGalleries(ids),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.GALLERIES],
        });
      },
    });

  const batchVerify = async (ids: any, cb?: any) => {
    try {
      if (!ids.length) return;
      await verifyGalleries(ids);
      showSuccessMessage(t);
      cb && cb?.();
    } catch (error) {
      showMutationError(error);
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedGallery) {
      deleteGallery(selectedGallery.id);
      setModalOpen(false);
    }
  };

  const handleApprove = (gallery: any) => {
    setSelectedGallery(gallery);
    if (!gallery.id) return;
    batchVerify([gallery.id]);
  };

  const handleDelete = (gallery: any) => {
    setSelectedGallery(gallery);
    setModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const columns = [
    {
      Header: 'Image',
      accessor: 'profilePictureThumbnail',
      Type: 'Image',
    },
    { Header: 'Gallery Name', accessor: 'name' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Opening Hours', accessor: 'openingHours' },
    {
      Header: 'Status',
      accessor: 'isVerified',
      body: ({ row }: { row: any }) => (
        <StatusBadge isVerified={row.isVerified} />
      ),
    },
  ];

  const actions = [
    { title: 'Verify', onClick: handleApprove },
    { title: 'Delete', onClick: handleDelete },
  ];

  return (
    <div>
      <CustomTable
        loading={isLoading}
        action={actions}
        data={data?.galleries || []}
        columns={columns}
        title="Galleries"
        btnTxt="Upload Artwork"
        openForm={false}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        totalPages={totalPages}
        error={error}
        handleVerify={batchVerify}
      />
      {isModalOpen && (
        <ConfirmationModal
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={handleDeleteConfirm}
          onCancel={() => setModalOpen(false)}
          title="Are You Sure?"
          message="Do you want to delete this gallery?"
        />
      )}
    </div>
  );
};

export default withPaginatedQuery(GalleriesDashboard, {
  queryFn: userService.getGalleries,
  queryKey: [CACHE_KEYS.GALLERIES],
});
