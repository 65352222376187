import { useTranslation } from 'react-i18next';

import Stacked from './charts/Stacked';
import StackedWithLine from './charts/Bars';
import {
  ArtistsSVG1,
  MuseumsSVG1,
  GalleriesSVG1,
  EnthusiastsSVG1,
} from '../../assets/SVGs';
import { useQuery } from '@tanstack/react-query';
import { CACHE_KEYS } from 'common';
import { adminService } from 'services';
import { useState } from 'react';

export default function Dashboard() {
  //local state
  const [stats, setStats] = useState({
    type: 'users',
    period: 'day',
  });

  //api calls
  const {
    data,
    isLoading: fetchingOverview,
    error: overviewError,
  } = useQuery({
    queryKey: [CACHE_KEYS.DASHBOARD_OVERVIEW],
    queryFn: () => adminService.getDashboardOverview(),
  });

  const {
    data: recentActivity,
    isLoading: fetchingRecentActivity,
    error: recentActivityError,
  } = useQuery({
    queryKey: [CACHE_KEYS.RECENT_ACTIVITY],
    queryFn: () => adminService.getRecentActivity(),
  });

  const {
    data: moderationStats,
    isLoading: fetchingModerationStats,
    error: moderationStatsError,
  } = useQuery({
    queryKey: [CACHE_KEYS.MODERATION_STATS],
    queryFn: () => adminService.getModerationStats(),
  });

  const {
    data: timeBaseStats,
    isLoading: fetchingTimeBaseStats,
    error: timeBaseStatsError,
  } = useQuery({
    queryKey: [CACHE_KEYS.TIME_BASE_STATS, stats.type, stats.period],
    queryFn: () =>
      adminService.getTimeBaseStats({
        type: stats.type,
        period: stats.period,
      }),
  });

  const currentEvents = [
    {
      date: '12',
      day: 'Sat',
      title: 'New US Artstyle',
      time: '10:00 to 11:00 AM',
    },
    {
      date: '19',
      day: 'Sat',
      title: 'ArtMania 2k25',
      time: '1:00 to 3:00 PM',
    },
    {
      date: '26',
      day: 'Sat',
      title: 'Contemporary Visions',
      time: '4:00 to 6:00 PM',
    },
    {
      date: '2',
      day: 'Sun',
      title: 'Sculpture Symposium',
      time: '11:00 AM to 1:00 PM',
    },
    {
      date: '9',
      day: 'Sun',
      title: 'Digital Dreams Art Show',
      time: '5:00 to 7:00 PM',
    },
    {
      date: '16',
      day: 'Sun',
      title: 'Street Art Festival',
      time: '12:00 PM to 5:00 PM',
    },
  ];

  const cards = [
    {
      title: 'total_art_enthusiasts',
      value: '1200',
      icon: <EnthusiastsSVG1 />,
      color: 'text-rose-500',
    },
    {
      title: 'total_artists',
      value: '130',
      icon: <ArtistsSVG1 />,
      color: 'text-orange-500',
    },
    {
      title: 'total_galleries',
      value: '250',
      icon: <GalleriesSVG1 />,
      color: 'text-green-500',
    },
    {
      title: 'total_museums',
      value: '183',
      icon: <MuseumsSVG1 />,
      color: 'text-blue-500',
    },
  ];

  const { t } = useTranslation();

  return (
    <div className="space-y-6 ">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {cards.map((item, index) => (
          <div
            key={index}
            className="bg-white p-1 md:p-2 xl:p-6 rounded-lg shadow-sm"
          >
            {item.icon}
            <p className="mt-2 pl-1 text-md font-semibold text-[#202020]">
              {t(item.title)}
            </p>

            <div className="text-2xl pl-1 font-bold">{item.value}</div>
          </div>
        ))}
      </div>

      <div className="bg-white p-1 md:p-3 xl:p-6 rounded-lg shadow-sm">
        <StackedWithLine />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white  rounded-lg shadow-sm">
          <div className="h-[300px] w-full rounded-lg p-1 md:p-3 xl:p-6 ">
            <Stacked />
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold">{t('upcoming_events')}</h2>
            <div className="flex flex-wrap gap-2">
              <select className="border border-[#A89F91] rounded-md px-2 py-1 text-md text-[#202020]">
                <option>Filter</option>
                <option>All</option>
              </select>
              <select className="border border-[#A89F91] rounded-md px-2 py-1 text-md text-[#202020]">
                <option>All</option>
                <option>Filter</option>
              </select>
            </div>
          </div>
          <div className="space-y-4 h-[250px] scroll-auto overflow-auto">
            {currentEvents.map((event, index) => (
              <div
                key={index}
                className="flex gap-4 items-start bg-[#F2EFE8]  p-1 md:p-3 xl:p-5  rounded-xl"
              >
                <div className="bg-[#FFFFFF] py-1 px-4 rounded-lg font-bold text-center text-[#202020] min-w-[60px]">
                  <div className="text-2xl  ">{event.date}</div>
                  <div className="text-lg">{event.day}</div>
                </div>
                <div>
                  <h3 className="font-bold text-xl text-[#202020]">
                    {event.title}
                  </h3>
                  <p className="text-md mt-1 text-[#A89F91]">{event.time}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
