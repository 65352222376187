const Pagination = ({
  goToPage,
  totalPages,
  currentPage,
  goToNextPage,
  goToPreviousPage,
}) => {
  return (
    <>
      <div className="flex justify-center w-full">
        <div className=" mt-8  mx-auto bg-primary flex">
          {[...Array(totalPages)].map((_, index) => (
            <div
              key={index}
              onClick={() => goToPage(index + 1)}
              className={`px-3 py-1 my-1 mx-2  ${
                currentPage === index + 1
                  ? 'bg-primaryLight'
                  : 'hover:bg-primaryLight bg-primary'
              } border-primary rounded`}
            ></div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-1 space-x-2">
        <div
          onClick={goToPreviousPage}
          className={`px-3 py-1  ${
            currentPage === 1 ? '  cursor-not-allowed' : '  cursor-pointer'
          } border-primary rounded`}
          disabled={currentPage === 1}
        >
          <svg
            width="12"
            height="25"
            fill="none"
            viewBox="0 0 12 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.54801 18.2322L8.48701 19.3225L2.70801 13.3804C2.61486 13.2852 2.54093 13.172 2.49048 13.0473C2.44003 12.9226 2.41406 12.7888 2.41406 12.6538C2.41406 12.5187 2.44003 12.3849 2.49048 12.2602C2.54093 12.1355 2.61486 12.0223 2.70801 11.9271L8.48701 5.98193L9.54701 7.07222L4.12301 12.6522L9.54801 18.2322Z"
              fill="black"
            />
          </svg>
        </div>

        {[...Array(totalPages)].map((_, index) => (
          <div
            key={index}
            onClick={() => goToPage(index + 1)}
            className={`px-3 py-1 hover:text-primary cursor-pointer border-primary rounded`}
          >
            {index + 1}
          </div>
        ))}

        <div
          onClick={goToNextPage}
          className={`px-3 py-1  ${
            currentPage === totalPages
              ? ' cursor-not-allowed'
              : '  cursor-pointer'
          } border-primary rounded`}
          disabled={currentPage === totalPages}
        >
          <svg
            width="12"
            height="25"
            fill="none"
            viewBox="0 0 12 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.45199 7.08223L3.51299 5.99195L9.29199 11.934C9.38514 12.0292 9.45907 12.1424 9.50952 12.2672C9.55997 12.3919 9.58594 12.5256 9.58594 12.6607C9.58594 12.7958 9.55997 12.9295 9.50952 13.0542C9.45907 13.1789 9.38514 13.2922 9.29199 13.3874L3.51299 19.3325L2.45299 18.2422L7.87699 12.6622L2.45199 7.08223Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Pagination;
