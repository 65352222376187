import React from 'react';
import { CACHE_KEYS } from 'common';
import { getArtist } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';

const filters = [
  {
    label: 'eventType',
    options: [
      'exhibition',
      'masterClass',
      'artFair',
      'artFestival',
      'opening',
      'performance',
    ],
  },
  {
    label: 'location',
    options: ['museum', 'gallery', 'artCenter', 'outdoor', 'online'],
  },
  {
    label: 'date',
    options: ['today', 'thisWeekend', 'nextWeek', 'nextMonth', 'customDate'],
  },
  {
    label: 'price',
    options: ['free', 'under50', '50to100', '100to200', 'over200'],
  },
];

const Artists: React.FC = () => {
  return (
    <PageTemplate
      filters={filters}
      accessor="artists"
      heading="allAuthors"
      fetchItems={getArtist}
      queryKey={CACHE_KEYS.ARTISTS}
    />
  );
};

export default Artists;
