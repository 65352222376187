import { useTranslation } from 'react-i18next';
import Card from './CardItem';
import { useNavigate } from 'react-router-dom';

const RecentItems = ({
  items,
  titleKey,
  descriptionKey,
  buttonTextKey,
  isArtWork = false,
  isArtType = false,
  isMuseums = false,
  authorType = false,
  isEventType = false,
  isGalleryType = false,
  link = '/collections',
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = t(titleKey) || 'Discover Galleries';
  const description = t(descriptionKey);
  const buttonText = t(buttonTextKey) || 'See All';

  return (
    <div className="py-8 mx-3">
      <div className="flex justify-between my-3 px-2">
        <div className="w-[70%]">
          <h2 className="font-cormorant font-bold text-xl md:text-4xl">
            {title}
          </h2>
          <p className="text-primary text-sm">{description}</p>
        </div>
        {Array.isArray(items) && items.length > 0 && (
          <div className="flex items-start sm:items-center py-3">
            <button
              className="bg-primary cursor-pointer font-bold text-Tlight px-1 md:px-16 xl:px-8 py-1"
              onClick={() => navigate(link || '/collections')}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10 mx-1">
        {Array.isArray(items) && items.length > 0 ? (
          items.map((item) => {
            if (isEventType) {
              return (
                <Card
                  key={item.id}
                  type={item.type}
                  title={item.title}
                  price={item.price}
                  author={item.author}
                  isEventType={isEventType}
                  description={item.location}
                  link={'/event-detail/' + item.id}
                  img={item.imageThumbnailUrl || item.imageUrl}
                />
              );
            }
            if (isGalleryType || isMuseums) {
              return (
                <Card
                  key={item.id}
                  authorType={true}
                  author={item.name}
                  artType={item.address}
                  img={item.profilePictureThumbnail || item.profilePicture}
                  link={
                    isMuseums
                      ? '/museum-detail/' + item.id
                      : '/gallery-detail/' + item.id
                  }
                />
              );
            }

            if (authorType) {
              return (
                <Card
                  key={item.id}
                  title={item.title}
                  style={item.style}
                  isArtType={isArtType}
                  gallery={item.gallery}
                  authorType={authorType}
                  artType={item.pseudonym}
                  author={item.firstName + ' ' + item.lastName}
                  img={item.profilePictureThumbnail || item.profilePicture}
                  link={
                    authorType
                      ? '/author-detail/' + item.id
                      : '/painting-detail/' + item.id
                  }
                />
              );
            }
            if (isArtWork) {
              return (
                <Card
                  isArtType
                  key={item.id}
                  title={item.title}
                  style={item?.ArtworkGenre?.title}
                  artType={item?.ArtworkMaterial?.title}
                  gallery={item.dimensions}
                  author={item.Artist?.pseudonym || '--'}
                  img={item.profilePictureThumbnail || item.imageUrl}
                  link={
                    isMuseums
                      ? '/museum-detail/' + item.id
                      : '/gallery-detail/' + item.id
                  }
                />
              );
            }

            return (
              <Card
                key={item.id}
                title={item.title}
                style={item.style}
                author={item.author}
                isArtType={isArtType}
                artType={item.artType}
                gallery={item.gallery}
                authorType={authorType}
                img={item.img || item.imageUrl}
                link={
                  authorType
                    ? '/author-detail/' + item.id
                    : '/painting-detail/' + item.id
                }
              />
            );
          })
        ) : (
          <p>{t('no_items_available')}</p>
        )}
      </div>
    </div>
  );
};

export default RecentItems;
