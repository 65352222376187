import React from 'react';

interface Review {
  reviewerName: string;
  reviewText: string;
  rating: number;
  reviewDate: string;
}

const dummyReviews: Review[] = [
  {
    reviewerName: 'John Doe',
    reviewText: 'Amazing artwork! Really enjoyed it.',
    rating: 4,
    reviewDate: 'July 22, 2024',
  },
  {
    reviewerName: 'Jane Smith',
    reviewText: 'A truly inspiring collection. Highly recommend!',
    rating: 3,
    reviewDate: 'August 10, 2024',
  },
  {
    reviewerName: 'Ad Nan',
    reviewText: 'Amazing artwork! Highly recommend.',
    rating: 5,
    reviewDate: 'July 22, 2024',
  },
  {
    reviewerName: 'Samuel Green',
    reviewText: 'Good work, but some pieces lacked originality.',
    rating: 2,
    reviewDate: 'September 5, 2024',
  },
];

const Reviews: React.FC<{ reviews?: Review[] }> = ({
  reviews = dummyReviews,
}) => {
  return (
    <div className="p-4 rounded-lg max-h-[400px] overflow-auto border border-[#dacfbe]">
      <div className="space-y-4">
        {reviews.map((review, index) => (
          <div
            key={index}
            className="border rounded-lg p-4 bg-[#ece7db] shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold">{review.reviewerName}</h3>
              <span className="text-sm text-gray-500">{review.reviewDate}</span>
            </div>
            <p className="text-gray-700 mb-2">{review.reviewText}</p>
            <div className="flex items-center">
              <div className="flex">
                {Array.from({ length: 5 }, (_, i) => (
                  <svg
                    key={i}
                    className={`w-5 h-5 ${
                      i < review.rating ? 'text-yellow-500' : 'text-gray-300'
                    }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.964a1 1 0 00.95.69h4.198c.969 0 1.371 1.24.588 1.81l-3.4 2.446a1 1 0 00-.364 1.118l1.286 3.964c.3.921-.755 1.688-1.538 1.118l-3.4-2.446a1 1 0 00-1.175 0l-3.4 2.446c-.783.57-1.838-.197-1.538-1.118l1.286-3.964a1 1 0 00-.364-1.118L2.35 9.39c-.783-.57-.38-1.81.588-1.81h4.198a1 1 0 00.95-.69l1.286-3.964z" />
                  </svg>
                ))}
              </div>
              <span className="ml-2 text-sm text-gray-600">
                {review.rating}/5
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
