import { useState } from 'react';
import { CACHE_KEYS } from 'common';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { adminService, userService } from 'services';

import queryClient from 'config/query';
import CustomTable from './CustomTable';
import SidebarModel from './SidebarModel';
import { commonUtils } from '../../utils';

import { useModal } from 'hooks/useModal';
import CreateEvent from './Event/CreateEvent';
import {
  jsonToFormData,
  showMutationError,
  showSuccessMessage,
} from 'utils/common';
import ConfirmationModal from './ConfirmationModal';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import EditEvent from './Event/EditEvent';
import { useTranslation } from 'react-i18next';

const Temp1 = ({ row }: any) => {
  const { t } = useTranslation();
  return (
    <p>
      {row?.isApproved ? (
        <div className="text-center p-1 rounded-full bg-green-100 text-green-700">
          {t('approved')}
        </div>
      ) : (
        <div className="text-center p-1 rounded-full bg-red-100 text-red-700">
          {t('blocked')}
        </div>
      )}
    </p>
  );
};

const EventsDashboard = ({
  isLoading: loading,
  data,
  paginationModel,
  totalPages,
  handlePageChange,
  search: searchQuery,
  setSearch: setSearchQuery,
}: any) => {
  const columns = [
    {
      Header: 'artwork.columns.image',
      accessor: 'imageUrl',
      Type: 'Image',
    },
    { Header: 'event.columns.eventName', accessor: 'title' },
    {
      Header: 'event.columns.organizer',
      accessor: 'artistOrganizer.pseudonym',
    },
    { Header: 'event.columns.location', accessor: 'location' },
    { Header: 'event.columns.eventDate', accessor: 'eventDate' },
    { Header: 'event.columns.eventTime', accessor: 'eventTime' },
    { Header: 'event.columns.organizerType', accessor: 'organizerType' },
    { Header: 'event.columns.price', accessor: 'price' },
    { Header: 'event.columns.eventType', accessor: 'EventType.title' },
    { Header: 'event.columns.status', accessor: 'status', body: Temp1 },
    { Header: 'event.columns.tags', accessor: 'tags' },
  ];

  const { t } = useTranslation();
  const { isPending: createLoading, mutateAsync: createEvent } = useMutation({
    mutationFn: (formdata: FormData) => adminService.createEvent(formdata),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
    },
    onSettled: () => {
      modalStateHandler('create', false);
    },
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState<Record<string, any> | null>(null);

  const { isPending: loadingDelete, mutateAsync: deleteEvent } = useMutation({
    mutationFn: (id: any) => adminService.deleteEventById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
      toast.success('Event deleted successfuly');
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  //update event
  const { isPending: loadingUpdate, mutateAsync: updateEvent } = useMutation({
    mutationFn: ({ id, formData }: any) =>
      adminService.updateEventById(id, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
      toast.success('Event updated successfuly');
    },
  });

  const { mutateAsync: toggleEventApproval } = useMutation({
    mutationFn: (id: any) => adminService.toggleEventApproval(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
      showSuccessMessage(t);
    },
  });

  const { modalState, modalStateHandler } = useModal();

  const confirmDelete = () => {
    deleteEvent(selected?.id);
    setModalOpen(false);
  };

  const hanldeDelte = (e: any) => {
    setModalOpen(true);
    setSelected(e);
  };

  const toggleEventApprove = (event: any) => {
    try {
      const id = event?.id;
      if (!id) return;
      toggleEventApproval(id);
    } catch (error) {
      showMutationError(error);
    }
  };

  const action = [
    {
      id: 'edit',
      title: 'Edit',
      onClick: (values: any) => {
        console.log('edit', values);
        setSelected(values);
        modalStateHandler('edit', true);
      },
      permission: 'view:dashboardStatistics',
    },
    {
      id: 'event-toggle',
      title: 'approve',
      onClick: toggleEventApprove,
      permission: 'view:dashboardStatistics',
    },
    { title: 'Delete', onClick: hanldeDelte },
  ];

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const hanldeSubmit = async (values: any) => {
    if (createLoading) {
      return;
    }
    values = commonUtils.parseFromPayload(values);

    const formData = jsonToFormData(values);
    await createEvent(formData);
  };

  const hanldeUpdated = async (values: any) => {
    values = commonUtils.parseFromPayload(values);

    try {
      let data = jsonToFormData(values);
      await updateEvent({ id: selected?.id, formData: data });
    } catch (error) {
      showMutationError(error);
    }
    modalStateHandler('edit', false);
  };

  return (
    <div>
      <CustomTable
        data={data?.events || []}
        error={''}
        title="events"
        action={action}
        columns={columns}
        loading={loading}
        btnTxt="create_event"
        totalPages={totalPages}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        openForm={() => modalStateHandler('create', true)}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={confirmDelete}
          onCancel={() => setModalOpen(false)}
          title="Do you want to delete this event?"
        />
      )}

      <SidebarModel
        title="createEvent"
        onClose={() => modalStateHandler('create', false)}
        isOpen={modalState.create}
      >
        <CreateEvent hanldeSubmit={hanldeSubmit} />
      </SidebarModel>

      {selected && (
        <SidebarModel
          title="editEvent"
          onClose={() => modalStateHandler('edit', false)}
          isOpen={modalState.edit}
        >
          <EditEvent
            key={selected.id}
            defaultValues={selected}
            hanldeSubmit={hanldeUpdated}
          />
        </SidebarModel>
      )}
    </div>
  );
};

// export default EventsDashboard;
export default withPaginatedQuery(EventsDashboard, {
  queryFn: userService.getEvents,
  queryKey: [CACHE_KEYS.EVENTS],
});
