import { TFunction } from 'i18next';
import { IFormField } from './base.dto';
import { baseDto } from 'dto';

export const eventInputs = ({ t }: { t: TFunction }) => {
  return [
    {
      name: 'title',
      label: t('event.fields.title'),
      type: 'text',
      max: 255,
    },
    {
      name: 'description',
      label: t('event.fields.description'),
      type: 'multiline',
      rows: 3,
      max: 500,
    },
    {
      name: 'eventDate',
      label: t('event.fields.eventDate'),
      type: 'text',
      max: 255,
    },
    {
      name: 'eventTime',
      label: t('event.fields.eventTime'),
      type: 'text',
      max: 255,
    },
    {
      name: 'price',
      label: t('event.fields.price'),
      type: 'number',
    },
    {
      name: 'location',
      label: t('event.fields.location'),
      type: 'text',
    },
    {
      name: 'status',
      label: t('event.fields.status'),
      type: 'text',
    },
    {
      name: 'eventType',
      label: t('event.fields.eventType'),
      type: 'text',
    },
    {
      name: 'tags',
      label: t('event.fields.tags'),
      type: 'multi-value',
    },
    {
      name: 'image',
      label: t('event.fields.image'),
      type: 'file',
    },
  ] satisfies IFormField[];
};

export const eventSchema = (t: TFunction) => {
  return baseDto.generateDtoHelper({
    prefix: 'event',
    fields: eventInputs({ t }),
    t,
  });
};
