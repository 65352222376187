import MuseumsDetails from '../components/Museums/Details';
import muesum from '../assets/museum/1.png';
import muesum2 from '../assets/museum/2.png';
import muesum3 from '../assets/museum/3.png';
import muesum4 from '../assets/museum/4.png';
import events3 from '../assets/events/IMG.png';
import events1 from '../assets/events/IMG-1.png';
import events2 from '../assets/events/IMG-2.png';
import events4 from '../assets/events/IMG-3.png'

const paintings = [
  {
    id: 1,
    img: muesum,
    title: 'title',
    author: 'Иван Иванов',
    artType: 'artType',
    style: 'cubism',
    gallery: 'Some Random Gallery',
  },
  {
    id: 2,
    img: muesum4,
    title: 'title',
    author: 'Иван Иванов',
    artType: 'artType',
    style: 'cubism',
    gallery: 'Some Random Gallery',
  },
  {
    id: 3,
    img: muesum2,
    title: 'title',
    author: 'Иван Иванов',
    artType: 'artType',
    style: 'cubism',
    gallery: 'Some Random Gallery',
  },
  {
    id: 4,
    img: muesum3,
    title: 'title',
    author: 'Иван Иванов',
    artType: 'artType',
    style: 'cubism',
    gallery: 'Some Random Gallery',
  },
];

const events = [
  {
    id: 1,
    img: events3,
    title: 'Организация А',
    price: 'Цена 450 000 сум',
    type: 'Тип мероприятия A',
    des: 'Lorem ipsum dolor sit amet consectetur. Sapien magnis sed vulputate ut vehicula.',
  },
  {
    id: 2,
    img: events1,
    title: 'Организация B',
    price: 'Цена 500 000 сум',
    type: 'Тип мероприятия B',
    des: 'Proin aliquet quam vel sapien lacinia, in accumsan justo tristique.',
  },
  {
    id: 3,
    img: events2,
    title: 'Организация C',
    price: 'Цена 600 000 сум',
    type: 'Тип мероприятия C',
    des: 'Suspendisse ultricies nisl in orci euismod, at feugiat leo viverra.',
  },
  {
    id: 4,
    img: events4,
    title: 'Организация D',
    price: 'Цена 700 000 сум',
    type: 'Тип мероприятия D',
    des: 'Vivamus sit amet justo a nibh tincidunt luctus in non libero.',
  },
];

function MuseumsDetail() {
  return (
    <div>
      <MuseumsDetails paintings={paintings} events={events} />
    </div>
  );
}

export default MuseumsDetail;
