import { useTranslation } from 'react-i18next';

const ItemProfile = ({ type = 'artist' }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center p-4">
      <div className=" flex flex-col md:flex-row justify-between">
        <div className="md:w-1/3">
          <h1 className="text-2xl font-semibold text-gray-800">
            Иван Иванов Иванович
          </h1>
          <p className="text-gray-600 mt-2">{t(type)}</p>
        </div>

        <div className="md:w-2/3 pl-0 pt-5 md:pt-1 md:pl-2">
          <p className="text-gray-700 text-sm leading-relaxed">
            Lorem ipsum dolor sit amet consectetur. Pellentesque elit nec urna
            eleifend placerat pretium maecenas. Accumsan aliquam nulla quis
            ullamcorper vulputate ornare purus. Risus eu mattis pharetra vel
            sagittis proin lectus ut. Vel iaculis euismod convallis sit nisl.
            Aliquam id urna id luctus. Felis tristique quis magna condimentum
            porttitor convallis orci pretium. Nibh sed morbi vitae sed amet leo
            in non nisl. Mi arcu diam adipiscing parturient auctor non tellus.
            Ac turpis vitae ac sapien commodo vestibulum id et scelerisque.
            Tempus dolor odio vel egestas nibh id habitant sagittis aliquet.
            Enim ornare diam dictum vestibulum quam mauris. Blandit tristique
            pulvinar tortor adipiscing.
          </p>

          <button className="mt-6 px-6 py-2 bg-primary font-semibold text-white">
            {t('readMore')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemProfile;
