import { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';

const StackedBarChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [view, setView] = useState('This Year');
  const { t } = useTranslation();

  const yearlyData = {
    labels: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ],
    datasets: [
      {
        label: 'User Registered',
        data: [30, 120, 25, 30, 40, 35, 50, 45, 20, 15, 10, 25],
        backgroundColor: '#3366FF',
        barThickness: 10,
      },
      {
        label: 'Artworks posted',
        data: [10, 15, 20, 10, 15, 25, 20, 15, 30, 20, 25, 10],
        backgroundColor: '#33CC66',
        barThickness: 10,
      },
      {
        label: 'Events created',
        data: [20, 30, 15, 20, 45, 40, 30, 20, 25, 10, 20, 30],
        backgroundColor: '#FF9933',
        barThickness: 10,
      },
    ],
  };

  const monthlyData = {
    labels: ['W1', 'W2', 'W3', 'W4'],
    datasets: yearlyData.datasets.map((dataset) => ({
      ...dataset,
      data: [10, 15, 5, 20],
    })),
  };

  const weeklyData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: yearlyData.datasets.map((dataset) => ({
      ...dataset,
      data: [5, 10, 15, 20, 25, 10, 5], // Sample weekly data
    })),
  };

  const getChartData = () => {
    switch (view) {
      case 'This Month':
        return monthlyData;
      case 'This Week':
        return weeklyData;
      default:
        return yearlyData;
    }
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: getChartData(),
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false, // Hide grid lines on x-axis
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,

            ticks: {
              stepSize: 20,
              callback: (value) => `${value}`,
            },
            grid: {
              color: '#f0f0f0', // Light gray grid lines
            },
          },
        },
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [view]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>{t('breakdown_of_case_types')}</h3>
        <select
        className="border border-gray-300 rounded-md px-3 py-1"
        value={view} onChange={(e) => setView(e.target.value)}>
          <option>This Year</option>
          <option>This Month</option>
          <option>This Week</option>
        </select>
      </div>
      <canvas ref={chartRef} style={{ width: '100%', height: '200px', maxHeight:"250px" }} />
    </div>
  );
};

export default StackedBarChart;
