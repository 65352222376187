import { create } from 'zustand';
import zukeeper from 'zukeeper';
import { apiInstance } from 'config/axios';

const useGalleryStore = create(
  zukeeper((set) => {
    const initialState = {
      total: 0,
      error: null,
      galleries: [],
      loading: false,
      recentGalleries: [],
      recentError: null,
      galleryError: null,
      galleryDetails: null,
      recentLoading: false,
      galleryLoading: false,
      totalResults: 0,
      totalPages: 0,
    };

    set(initialState);

    return {
      ...initialState,

      fetchRecentGalleries: async (limit = 4) => {
        set({ recentLoading: true, recentError: null });
        try {
          const response = await apiInstance.get('/api/galleries', {
            params: { limit },
          });
          set({
            recentGalleries: response.data.data
              ? response.data.data.galleries
              : [],
            recentLoading: false,
          });
        } catch (error) {
          set({
            recentLoading: false,
            recentError:
              error.response?.data?.message ||
              'Failed to fetch recent galleries. Please try again.',
          });
        }
      },
      fetchGalleries: async ({ page, limit = 10, search }) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get('/api/galleries', {
            params: {
              page,
              limit,
              search,
            },
          });
          set({
            loading: false,
            total: response.data?.totalPages || 0,
            totalPages: response.data?.totalPages || 0,
            galleries: response.data.data ? response.data.data.galleries : [],
          });
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch galleries. Please try again.',
          });
        }
      },

      fetchGalleryById: async (id) => {
        set({ galleryLoading: true, galleryError: null });
        try {
          const response = await apiInstance.get(`/api/galleries/${id}`);
          set({
            galleryDetails: response.data.data
              ? response.data.data.gallery
              : null,
            galleryLoading: false,
          });
          return response.data;
        } catch (error) {
          set({
            galleryLoading: false,
            galleryError:
              error.response?.data?.message ||
              'Failed to fetch gallery details. Please try again.',
          });
        }
      },

      createGallery: async (galleryData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            '/api/galleries',
            galleryData,
          );
          set((state) => ({
            galleries: [...state.galleries, response.data],
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to create gallery. Please try again.',
          });
        }
      },

      updateGallery: async (id, galleryData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.put(
            `/api/galleries/${id}`,
            galleryData,
          );
          set((state) => ({
            galleries: state.galleries.map((gallery) =>
              gallery.id === id ? { ...gallery, ...response.data } : gallery,
            ),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to update gallery. Please try again.',
          });
        }
      },

      deleteGallery: async (id) => {
        set({ loading: true, error: null });
        try {
          await apiInstance.delete(`/api/galleries/${id}`);
          set((state) => ({
            galleries: state.galleries.filter((gallery) => gallery.id !== id),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to delete gallery. Please try again.',
          });
        }
      },

      // Fetch artworks associated with a gallery by ID
      fetchGalleryArtworks: async (id) => {
        set({ galleryLoading: true, galleryError: null });
        try {
          const response = await apiInstance.get(
            `/api/galleries/${id}/artworks`,
          );
          return response.data;
        } catch (error) {
          set({
            galleryLoading: false,
            galleryError:
              error.response?.data?.message ||
              'Failed to fetch gallery artworks. Please try again.',
          });
        }
      },

      // Verify phone number of a gallery (specific for the gallery itself)
      verifyPhone: async (code) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            '/api/galleries/verify-phone',
            {
              code,
            },
          );
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to verify phone. Please try again.',
          });
        }
      },

      // Upload profile picture for a gallery
      uploadProfilePicture: async (id, formData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            `/api/galleries/${id}/profile-picture`,
            formData,
          );
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to upload profile picture. Please try again.',
          });
        }
      },

      // Admin actions: get all galleries (for admin)
      fetchAllGalleriesAdmin: async () => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get('/api/galleries/admin/all');
          set({
            galleries: response.data.data ? response.data.data.galleries : [],
            loading: false,
          });
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch galleries. Please try again.',
          });
        }
      },

      // Admin actions: update gallery info (for admin)
      updateGalleryAdmin: async (id, galleryData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.put(
            `/api/galleries/admin/${id}`,
            galleryData,
          );
          set((state) => ({
            galleries: state.galleries.map((gallery) =>
              gallery.id === id ? { ...gallery, ...response.data } : gallery,
            ),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to update gallery. Please try again.',
          });
        }
      },

      // Admin actions: delete a gallery (for admin)
      deleteGalleryAdmin: async (id) => {
        set({ loading: true, error: null });
        try {
          await apiInstance.delete(`/api/galleries/admin/${id}`);
          set((state) => ({
            galleries: state.galleries.filter((gallery) => gallery.id !== id),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to delete gallery. Please try again.',
          });
        }
      },

      // Admin actions: verify a gallery
      verifyGalleryAdmin: async (id) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            `/api/galleries/admin/${id}/verify`,
          );
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to verify gallery. Please try again.',
          });
        }
      },

      // Clear error state
      clearError: () => set({ error: null }),

      // Reset galleries state
      resetGalleries: () => set({ galleries: [], loading: false, error: null }),
    };
  }),
);

window.store = useGalleryStore; // For debugging, you can view the store in the console

export default useGalleryStore;
