import { TFunction } from 'i18next';
import { IFormField } from './base.dto';
import { baseDto } from 'dto';

export const artworkInputs = ({ t }: { t: TFunction }) => {
  return [
    {
      name: 'title',
      label: t('artwork.fields.title'),
      type: 'text',
      max: 255,
    },
    {
      name: 'description',
      label: t('artwork.fields.description'),
      type: 'multiline',
      rows: 3,
      max: 500,
    },
    {
      name: 'medium',
      label: t('artwork.fields.medium'),
      type: 'text',
      max: 255,
    },
    {
      name: 'dimensions',
      label: t('artwork.fields.dimensions'),
      type: 'text',
      max: 255,
    },
    {
      name: 'price',
      label: t('artwork.fields.price'),
      type: 'number',
    },
    {
      name: 'genre',
      label: t('artwork.fields.genre'),
      type: 'text',
    },
    {
      name: 'material',
      label: t('artwork.fields.material'),
      type: 'text',
    },
    {
      name: 'tags',
      label: t('artwork.fields.tags'),
      type: 'multi-value',
    },
    {
      name: 'image',
      label: t('artwork.fields.image'),
      type: 'file',
    },
    {
      name: 'orientation',
      label: t('artwork.fields.orientation'),
      type: 'select',
      options: [
        {
          value: 'landscape',
          label: t('artwork_landscape'),
        },
        {
          value: 'portrait',
          label: t('artwork_portrait'),
        },
      ],
    },
    {
      name: 'financialStatus',
      label: t('artwork.fields.forSale'),
      type: 'select',
      options: [
        {
          value: 'not-for-sale',
          label: t('artwork_notForSale'),
        },
        {
          value: 'for-sale',
          label: t('artwork_forSale'),
        },
        {
          value: 'sold',
          label: t('artwork_sold'),
        },
      ],
    },
    {
      name: 'visibility',
      label: t('artwork.fields.visibility'),
      type: 'select',
      options: [
        {
          value: 'public',
          label: t('public_visibility'),
        },
        {
          value: 'private',
          label: t('private_visibility'),
        },
      ],
    },
  ] satisfies IFormField[];
};

export const artworkSchema = (t: TFunction) => {
  return baseDto.generateDtoHelper({
    prefix: 'artwork',
    fields: artworkInputs({ t }),
    t,
  });
};
