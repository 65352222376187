import BaseArtWorkForm from './BaseForm';

const EditArtWork = ({ defaultValues, hanldeSubmit }) => {
  return (
    <BaseArtWorkForm
      submitHandler={hanldeSubmit}
      defaultValues={defaultValues}
    />
  );
};

export default EditArtWork;
