import { CACHE_KEYS } from 'common';
import { getEvents } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';

const filters = [
  {
    label: 'eventType',
    options: [
      'exhibition',
      'masterclass',
      'artFair',
      'artFestival',
      'opening',
      'performance',
    ],
  },
  {
    label: 'location',
    options: ['location1', 'location2', 'location3'],
  },
  {
    label: 'date',
    options: ['date1', 'date2', 'date3'],
  },
  {
    label: 'price',
    options: ['price1', 'price2', 'price3'],
  },
];

const Events = () => {
  return (
    <PageTemplate
      heading="events"
      accessor="events"
      filters={filters}
      fetchItems={getEvents}
      queryKey={CACHE_KEYS.EVENTS}
    />
  );
};

export default Events;
