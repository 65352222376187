import { apiInstance } from 'config/axios';
import { useLayoutEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// mian pages
import Home from '../pages/Home';
import About from '../pages/About';
import Login from '../pages/Login';
import Events from '../pages/Events';
import Artists from '../pages/Artists';
import Museums from '../pages/Museums';
import Account from '../pages/Account';
import Confirm from '../pages/Confirm';
import Register from '../pages/Register';
import Galleries from '../pages/Galleries';
import Collections from '../pages/Collections';
import EventDetails from '../pages/EventDetail';
import AuthorsDetail from '../pages/AuthorsDetail';
import MuseumsDetail from '../pages/MuseumsDetail';
import GalleryDetail from '../pages/GalleryDetail';
import ResetPassword from '../pages/ResetPassword';
import PaintingDetail from '../pages/PaintingDetail';

// AdminPanel pages
import Users from '../components/AdminPanel/Users';
import Layout from '../components/AdminPanel/Layout';
import Profile from '../components/AdminPanel/Profile';
import Dashboard from '../components/AdminPanel/Dashboard';
import EventsDashboard from '../components/AdminPanel/Events';
import ArtistsDashboard from '../components/AdminPanel/Artists';
import MuseumsDashboard from '../components/AdminPanel/Museums';
import Notifications from '../components/AdminPanel/Notifications';
import GalleriesDashboard from '../components/AdminPanel/Galleries';
import ArtworkDashboard from '../components/AdminPanel/ArtWork';

// custom route
import useUserStore from '../store';
import GuestRoute from './GuestRoute';
import PrivateRoute from './PrivateRoutes';
import { hasPermission } from '../utils';

function Navigation() {
  const { isAuthenticated, userType, setUserType, setUser, logout } =
    useUserStore();
    const [loading, setLoading] = useState(false);

  const getDetail = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const res = await apiInstance.get('/api/users/me', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { user, userType } = res?.data;
      setUser(user);
      setUserType(userType);
    } catch (E) {
      logout();
      console.log('Err', E.status);
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/login"
          element={
            <GuestRoute isLogin={isAuthenticated}>
              <Login />
            </GuestRoute>
          }
        />
        <Route
          path="/register"
          element={
            <GuestRoute isLogin={isAuthenticated}>
              <Register />
            </GuestRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <GuestRoute isLogin={isAuthenticated}>
              <ResetPassword />
            </GuestRoute>
          }
        />

        <Route
          path="/account"
          element={
            <PrivateRoute isLogin={isAuthenticated}>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path="/confirm-email/:token"
          element={
            <GuestRoute isLogin={isAuthenticated}>
              <Confirm />
            </GuestRoute>
          }
        />

        <Route path="/events" element={<Events />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/museums" element={<Museums />} />
        <Route path="/galleries" element={<Galleries />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/event-detail/:id" element={<EventDetails />} />
        <Route path="/author-detail/:id" element={<AuthorsDetail />} />
        <Route path="/museum-detail/:id" element={<MuseumsDetail />} />
        <Route path="/gallery-detail/:id" element={<GalleryDetail />} />
        <Route path="/painting-detail/:id" element={<PaintingDetail />} />
      </Routes>

      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute isLogin={hasPermission(userType, 'view:dashboard')}>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route
            path="users"
            element={
              <PrivateRoute
                isLogin={hasPermission(userType, 'view:dashboardUsers')}
              >
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="artwork"
            element={
              <PrivateRoute
                isLogin={hasPermission(userType, 'view:dashboardArtwork')}
              >
                <ArtworkDashboard />
              </PrivateRoute>
            }
          />
          <Route path="profile" element={<Profile />} />
          <Route
            path="events"
            element={
              <PrivateRoute
                isLogin={hasPermission(userType, 'view:dashboardEvents')}
              >
                <EventsDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="museums"
            element={
              <PrivateRoute
                isLogin={hasPermission(userType, 'view:dashboardMuseums')}
              >
                <MuseumsDashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="artists"
            element={
              <PrivateRoute
                isLogin={hasPermission(userType, 'view:dashboardArtist')}
              >
                <ArtistsDashboard />
              </PrivateRoute>
            }
          />
          <Route path="notifications" element={<Notifications />} />
          <Route
            path="galleries"
            element={
              <PrivateRoute
                isLogin={hasPermission(userType, 'view:dashboardGallery')}
              >
                <GalleriesDashboard />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default Navigation;
