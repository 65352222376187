import axios from 'axios';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import logo from '../logo.svg';
import useUserStore from '../store';
import Loader from '../components/UiComponents/Loader';

const Confirm = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const { resendVerifyEmail } = useUserStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      handleVerify(token);
    }
  }, [token]);

  const handleVerify = async (token: any) => {
    setLoading(true);
    const url = '/api/users/verify-email/' + token;

    try {
      const response = await axios.get(url);
      toast.success(
        response?.data?.data?.message || 'Email verified successfuly',
      );
      navigate('/login');
    } catch (error: any) {
      const errMsg = error?.message || 'Token expired please verify again';
      setError(errMsg);
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setLoading(true);
    const email = localStorage.getItem('registerEmail');
    resendVerifyEmail(email, () => setLoading(false));
  };

  return (
    <div className="flex items-center min-h-screen  ">
      <div className=" flex items-center justify-center max-w-[1440px] mx-auto my-4 overflow-hidden min-h-[600px] ">
        <div className="">
          <div className="mb-6">
            <Link to="/">
              <img src={logo} alt="Arthub Logo" className="h-16" />
            </Link>
          </div>
          <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
            Email Confirmation
          </h2>
          {loading ? (
            <>
              <p className="text-gray-600 mt-2 mb-8">Please wait.</p>
              <span className="mx-4 text-gray-500">
                <Loader />
              </span>
            </>
          ) : error ? (
            <div className="flex mt-2 mb-8">
              {' '}
              <p className="text-gray-600 mr-1">{error}</p>
              <button className="text-primary" onClick={handleReset}>
                {' '}
                Resend email
              </button>
            </div>
          ) : null}

          <div className="flex items-center my-6">
            <hr className="flex-grow border-primary" />
            <span className="mx-4 text-gray-500">OR</span>
            <hr className="flex-grow border-primary" />
          </div>

          <p className="mt-6 text-center text-gray-600">
            Go to{' '}
            <Link
              to="/login"
              className="text-gray-900 font-medium hover:underline"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
