import * as yup from 'yup';
import { TFunction } from 'i18next';

export type IFormOption = {
  label: string;
  value: string;
};

function translateWithPlaceholders(
  template: string,
  replacements: Record<string, string | number>,
): string {
  const result = template.replace(
    /{{\s*([^}]+)\s*}}/g,
    (match, key) => String(replacements[key]) || match,
  );
  return result;
}

export const stringSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
  max: number = 255,
) => {
  return yup
    .string()
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    )
    .max(
      max,
      translateWithPlaceholders(t('formErrors.maxLength'), {
        field: t(`${prefix}.fields.${fieldName}`),
        max,
      }),
    );
};

export const numberSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup
    .number()
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    )
    .min(
      0,
      translateWithPlaceholders(t('formErrors.positiveNumber'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    )
    .typeError(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    );
};

export const booleanSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup.boolean().required(
    translateWithPlaceholders(t('formErrors.required'), {
      field: t(`${prefix}.fields.${fieldName}`),
    }),
  );
};

export const dateSchema = (t: TFunction, fieldName: string, prefix: string) => {
  return yup.date().required(
    translateWithPlaceholders(t('formErrors.required'), {
      field: t(`${prefix}.fields.${fieldName}`),
    }),
  );
};

export const optionsSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    );
};

export const multipleOptionsSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup
    .array()
    .of(optionsSchema(t, fieldName, prefix))
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    );
};

export const emailSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup
    .string()
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    )
    .email(
      translateWithPlaceholders(t('formErrors.email'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    );
};

export const imageSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup.mixed().required(
    translateWithPlaceholders(t('formErrors.required'), {
      field: t(`${prefix}.fields.${fieldName}`),
    }),
  );
};

export const passwordSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup
    .string()
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    )
    .min(6, t('formErrors.passwordLength'));
};

export const mutiValueSchema = (
  t: TFunction,
  fieldName: string,
  prefix: string,
) => {
  return yup
    .array()
    .of(optionsSchema(t, fieldName, prefix))
    .required(
      translateWithPlaceholders(t('formErrors.required'), {
        field: t(`${prefix}.fields.${fieldName}`),
      }),
    );
};

export type FieldType =
  | 'text'
  | 'multiline'
  | 'email'
  | 'password'
  | 'number'
  | 'date'
  | 'file'
  | 'boolean'
  | 'select'
  | 'multi-select'
  | 'multi-value';

export type IFormField = {
  type: FieldType;
  name: string;
  label: string;
  options?: { value: string; label: string }[];
  max?: number;
  rows?: number;
};
export const generateDtoHelper = ({
  prefix,
  t,
  fields,
}: {
  prefix: string;
  t: TFunction;
  fields: IFormField[];
}) => {
  const dto = fields.reduce((prev, field) => {
    let dtoSchmea;
    switch (field.type) {
      case 'text':
      case 'multiline':
        dtoSchmea = stringSchema(t, field.name, prefix, field.max);
        break;
      case 'email':
        dtoSchmea = emailSchema(t, field.name, prefix);
        break;
      case 'password':
        dtoSchmea = passwordSchema(t, field.name, prefix);
        break;
      case 'number':
        dtoSchmea = numberSchema(t, field.name, prefix);
        break;
      case 'date':
        dtoSchmea = dateSchema(t, field.name, prefix);
        break;
      case 'file':
        dtoSchmea = imageSchema(t, field.name, prefix);
        break;
      case 'boolean':
        dtoSchmea = booleanSchema(t, field.name, prefix);
        break;
      case 'select':
        dtoSchmea = optionsSchema(t, field.name, prefix);
        break;
      case 'multi-value':
        dtoSchmea = mutiValueSchema(t, field.name, prefix);
        break;
      case 'multi-select':
        dtoSchmea = multipleOptionsSchema(t, field.name, prefix);
        break;
      default:
        dtoSchmea = stringSchema(t, field.name, prefix);
        break;
    }
    prev[field.name] = dtoSchmea;
    return prev;
  }, {} as Record<string, yup.AnySchema>);

  return yup.object().shape(dto).required();
};
