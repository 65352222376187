import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AboutContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;
  font-family: 'Cormorant', serif;
  color: #202020;
`;

const Title = styled.h1`
  font-size: 38px;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 80px;
`;

const SectionTitle = styled.h2`
  font-size: 30px;
  margin: 10px 0px;
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Poppins';
`;

const Slogan = styled.div`
  font-size: 24px;
  font-style: italic;
  text-align: center;
  margin: 0px 0px 50px;
`;

const About: React.FC = () => {
  const { t } = useTranslation();
  return (
    <AboutContainer>
      <Title>{t('about_title')}</Title>
      <Slogan>{t('slogan')}</Slogan>
      <Section>
        <SectionTitle>{t('mission_title')}</SectionTitle>
        <Paragraph>{t('mission_paragraph_1')}</Paragraph>
        <Paragraph>{t('mission_paragraph_2')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('vision_title')}</SectionTitle>
        <Paragraph>{t('vision_paragraph_1')}</Paragraph>
        <Paragraph>{t('vision_paragraph_2')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('about_title_section')}</SectionTitle>

        <Paragraph>{t('about_paragraph_1')}</Paragraph>
        <Paragraph>{t('about_paragraph_2')}</Paragraph>
      </Section>
    </AboutContainer>
  );
};

export default About;
