import styled from 'styled-components';
import logo from '../../logo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #202020;
  margin: 3px 5px;
  font-size: 18px;
  &:hover {
    color: #a89f91;
  }
`;

const Logo = styled.img`
  height: 60px;
  margin-bottom: 10px;
`;

const LogoText = styled.h2`
  font-size: 24px;
  margin: 0;
  color: #202020;
`;

const ContactSection = styled.div`
  text-align: left;
  font-size: 16px;
  color: #202020;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 20px;
  }
`;

const Email = styled.a`
  color: #202020;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LinksSection = styled.div`
  display: flex;
  gap: 60px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #202020;
  text-aligh: center;
`;

const NewsletterSection = styled.div`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 20px;
  }
`;


const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`;

const SocialIcon = styled.a`
  color: #202020;
  font-size: 20px;
  text-decoration: none;
  &:hover {
    color: #a89f91;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  font-size: 14px;
  color: #a89f91;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer>
        <LinksSection className="max-w-[1240px] mx-auto px-2">
          <LogoSection className="md:w-[30%] px-3">
            <Link to="/">
              <Logo src={logo} alt="Arthub Gallery" />
            </Link>
            <LogoText>ART HUB</LogoText>
            <ContactSection>
              <p>{t("contact_us")}</p>
              <Email href="mailto:support@tropa.gallery">
                support@arthub@gallery
              </Email>
            </ContactSection>
          </LogoSection>

          <LinksColumn className="md:w-[15%] text-center md:text-left">
            <ColumnTitle>{t('quick_links')}</ColumnTitle>

            <NavLink to="/collections">{t('collections')}</NavLink>
            <NavLink to="/galleries">{t('galleries')}</NavLink>
            <NavLink to="/museums">{t('museums')}</NavLink>
          </LinksColumn>

          <LinksColumn className="md:w-[15%] text-center md:text-left">
            <ColumnTitle>{t('company')}</ColumnTitle>
            <NavLink to="/about">{t('about')}</NavLink>
            <NavLink to="/contact">{t('contact')}</NavLink>
            <NavLink to="/privacy-policy">{t('privacy_policy')}</NavLink>
          </LinksColumn>

          <NewsletterSection className="md:w-[30%] text-center md:text-left px-3">
            
            <ColumnTitle className='text-center'>{t('socialLinks')}</ColumnTitle>
            <SocialLinks>

              <SocialIcon
                href="https://t.me/tropagallery"
                target="_blank"
                aria-label="Telegram"
              >
                <i className="fab fa-telegram-plane"></i>
              </SocialIcon>
              <SocialIcon
                target="_blank"
                href="https://www.tiktok.com/@tropa.gallery"
                aria-label="TikTok"
              >
                <i className="fab fa-tiktok"></i>
              </SocialIcon>
              <SocialIcon
                target="_blank"
                href="https://www.pinterest.com/tropagallery/"
                aria-label="Pinterest"
              >
                <i className="fab fa-pinterest-p"></i>
              </SocialIcon>
              <SocialIcon
                target="_blank"
                href="https://www.youtube.com/@tropagallery"
                aria-label="YouTube"
              >
                <i className="fab fa-youtube"></i>
              </SocialIcon>
              <SocialIcon
                target="_blank"
                href="https://www.instagram.com/tropa.gallery/"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram"></i>
              </SocialIcon>
            </SocialLinks>
          </NewsletterSection>
        </LinksSection>
        <FooterBottom className="mt-4 mb-2">
          © 2024. Art Hub Gallery. {t('all_rights_reserved')}
        </FooterBottom>
      </footer>
    </>
  );
};

export default Footer;
