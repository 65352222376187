import React, { ReactNode } from 'react';

interface ConfirmationModalProps {
  title: string;
  message: string;
  onCancel: () => void;
  cancelLabel?: string;
  children?: ReactNode;
  onConfirm: () => void;
  confirmLabel?: string;
  confirmColor?: string;
  icon?: React.ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  icon,
  title,
  message,
  onCancel,
  children,
  onConfirm,
  cancelLabel = 'Cancel',
  confirmColor = '#A89F91',
  confirmLabel = 'Confirm',
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md px-6 pb-6 pt-10 m-3 relative">
        {children ? (
          children
        ) : (
          <>
            <div className="flex justify-center mb-4">
              {icon && <div className="text-[#A89F91]">{icon}</div>}
            </div>
            <h2 className="text-center text-[#344054] font-semibold mb-2">
              {title}
            </h2>
            <p className="text-center text-[#202020] mb-4">
              {message || ''}
            </p>
            <hr />
            <div className="mt-4 flex justify-center gap-4">
              <button
                onClick={onConfirm}
                className="py-2 px-4 rounded-md text-white"
                style={{ backgroundColor: confirmColor }}
              >
                {confirmLabel}
              </button>
              <button
                onClick={onCancel}
                className="border border-[#A89F91] py-2 px-4 rounded-md text-[#A89F91]"
              >
                {cancelLabel}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmationModal;
